// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import 'tempusdominus-bootstrap-4'

import 'bootstrap'
import 'select2'

import 'x-editable-bs4/dist/bootstrap4-editable/js/bootstrap-editable'
import 'x-editable-bs4/dist/bootstrap4-editable/css/bootstrap-editable'

import 'datatables.net'
import 'datatables.net-bs4'
import 'datatables.net-responsive'
import 'datatables.net-responsive-bs4'
import Dropzone from 'dropzone'
import ClipboardJS from 'clipboard'
import TurndownService from 'turndown'

import '../stylesheets/application'
import '../common'
import '../contests'
import '../creatures'
import '../users'
import '../reported'
import '../books'
import {Images} from '../data'

Dropzone.autoDiscover = false;

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  $('.editable').editable({
    success: function(response, newValue) {
      $(this).html(response);
    }
  });

  if ($('#artwork-dropzone').length > 0) {
    let artworkDz = new Dropzone('#artwork-dropzone', {
      paramName: "artwork", // The name that will be used to transfer the file
      uploadMultiple: true,
      success: function(file, response) {
        this.removeFile(file);
        $('.artwork-list').replaceWith(response);
      }
    });
  }

  if ($('#copy-to-clipboard').length > 0) {
    new ClipboardJS('#copy-to-clipboard', {
      text: function(trigger) {
        var textPreRE = /<span class="text-pre">(.+)<\/span>/msg;
        var html = $("#monster-output").html().replaceAll(textPreRE, "<pre> $1</pre>");
        return createTurndownService().turndown(html);
      }
    });
  }

  if ($('#copy-all-to-clipboard').length > 0) {
    new ClipboardJS('#copy-all-to-clipboard', {
      text: function(trigger) {
        var textPreRE = /<span class="text-pre">(.+)<\/span>/msg;
        var html = $("#all-monster-output").html().replaceAll(textPreRE, "<pre> $1</pre>");
        return createTurndownService().turndown(html);
      }
    });
  }
})

function createTurndownService() {
  //return new TurndownService().turndown(document.getElementById("monster-output"));
  var turndownService = new TurndownService({
    preformattedCode: true,
    defaultReplacement: function(content, node, options) {
      // capitalize name and category
      if (node.parentElement.className.match(/h3/)) {
        return `${options.strongDelimiter}${content.toUpperCase()}${options.strongDelimiter} `;
      } else if (node.className.match(/btn-pf-rare/)) {
        return `[kwR]${content.toUpperCase()}[/kwR] `;
      } else if (node.className.match(/btn-pf-alignment/)) {
        return `[kwA]${content.toUpperCase()}[/kwA] `;
      } else if (node.className.match(/btn-pf-size/)) {
        return `[kwS]${content.toUpperCase()}[/kwS] `;
      } else if (node.className.match(/btn-pf/)) {
        return `[kw]${content.toUpperCase()}[/kw] `;
      } else if (node.className.match(/font-weight-bold/)) {
        return `${options.strongDelimiter}${content}${options.strongDelimiter}`;
      } else if (node.className.match(/font-italic/)) {
        return `${options.emDelimiter}${content}${options.emDelimiter}`;
      } else if (node.className.match(/word-count/)) {
        return "";
      } else if (node.className.match(/art-direction/)) {
        return "";
      } else {
        return (node.isBlock && !node.className.match(/d-inline-block/)) ? content + '\n\n' : content;
      }
    },
    blankReplacement: function(content, node, options) {
      if (node.getAttribute("style") && node.getAttribute("style").match(/background: rgb/)) {
        return `${options.hr}\n\n`;
      } else {
        return node.isBlock ? '\n\n' : ''
      }
    }
  });

  turndownService.addRule("pre-text", {
    filter: 'pre',
    replacement: function(content, node, options) {
      return content.replaceAll("\n", "\n\n");
    }
  });

  turndownService.addRule("action-images", {
    filter: 'img',
    replacement: function(content, node, options) {
      var num = null;

      switch(node.src) {
        case Images.a:
          num = 1;
          break;
        case Images.aa:
          num = 2;
          break;
        case Images.aaa:
          num = 3;
          break;
        case Images.f:
          num = 4;
          break;
        case Images.r:
          num = 5;
          break;
      }

      if (num == null) {
        return content;
      } else {
        return ` [icon]${num}[/icon] `;
      }

    }

  });

  return turndownService;
}

window.showNotice = function(notice) {
  $('#notice').html(notice).show().css('opacity', 1.0)
    .animate({opacity: 0}, {
      duration: 10000,
      complete: function() {
        $('#notice').hide();
      }
    });
}

window.showAlert = function(notice) {
  $('#alert').html(notice).show().css('opacity', 1.0)
    .animate({opacity: 0}, {
      duration: 10000,
      complete: function() {
        $('#alert').hide();
      }
    });
}



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
