import {createEditor} from "creature_editor";
import './stylesheets/editor'

$(function() {
	$(document).on('click', '.vote-value', function(evt) {
		$(this).closest('.category').find('.vote-value').removeClass('selected');
		$(this).addClass('selected');
		const categoryCount = $(this).closest('.vote').find('.category').length;
		const selectedCount = $(this).closest('.vote').find('.vote-value.selected').length;
		if (categoryCount == selectedCount) {
			$(this).closest('.vote').find('#submit-vote').prop('disabled', false);
		} else {
			$(this).closest('.vote').find('#submit-vote').prop('disabled', true);
		}
		evt.stopPropagation();
		return false;
	});

	// Make sure we're using Bootstrap tooltip (and not tooltip from datatables)
	if ( $('.table').length ) {
		$('body').tooltip({selector: '[data-toggle="tooltip"]'});
	}

	if ($('#monsters-table').length) {
		let monstersTable = $('#monsters-table').DataTable({
			processing: true,
			serverSide: true,
			ajax: '/creatures.json',
			columns: [
				{data: 'level'},
				{data: 'name'},
				{data: 'type'},
				{data: 'size'},
				{data: 'alignment'},
				{data: 'author'},
				{data: 'created'},
				{
					searchable: false, 
					orderable: false,
					render: (data, type, row, meta) => {
						let links = `<a href="/creatures/${row.id}" data-toggle="tooltip" data-placement="bottom" title="View"><i class="far fa-eye"></i></a>`;
						if (row.canCopy) {
							links = links + `  <a href="/creatures/${row.id}/copy" data-method="patch" data-confirm="Are you sure you want to copy monster ${row.name}?" data-toggle="tooltip" data-placement="bottom" title="Copy"><i class="far fa-copy"></i></a>`
						}
						return links;
					}
				}
			],
			order: [[1, 'asc']],
			bAutoWidth: false,
			responsive: {
        details: false
    	}
		});

		$('#monsters-table').on('click', 'td', function() {
			let tr = $(this).closest('tr');
			let tbody = $(this).closest('tbody');
			let row = monstersTable.row(tr);
			let trBgdClass = tr.attr('class');

			if ( row.child.isShown() ) {
				// This row is already open - close it
				row.child("").hide();
				tr.removeClass('shown');
			}
			else {
				let creatureId = row.data()['id'];
				$.ajax(`/creatures/${creatureId}`, {
					method: 'GET'
				})
					.done((data, status, xhr) => {
						// Open this row
						row.child(data).show();
						row.child().addClass(trBgdClass);
            window.creatureId = creatureId;
						createEditor('#app', window.creatureData, 'view', creatureId);
						$('#monsters-table tr').removeClass('shown');
						tr.addClass('shown');
					})
					.fail(() => {
						console.log(arguments);
					});
			}

			tbody.find('tr').each(function(idx, r) {
				if (r != tr) {
					let row = monstersTable.row(r);
					row.child.hide();
				}
			});
		});
	}

	if ($('#my-monsters-table').length) {
		let judge = $('#my-monsters-table').data('judge');
		let myMonstersTable = $('#my-monsters-table').DataTable({
			processing: true,
			serverSide: true,
			ajax: '/creatures/my.json',
			columns: [
				{data: 'level'},
				{data: 'name'},
				{data: 'type'},
				{data: 'size'},
				{data: 'alignment'},
				{
					render: (data, t, row, m) => {
						return row.contest ? 
              (row.canViewContest ? `<a href="/contests/${row.contest.id}">${row.contest.name}</a>` : row.contest.name) 
              : ''
					}
				},
				{data: 'state'},
				{
					visible: judge,
					data: 'judged'
				},
				{data: 'created'},
				{
					searchable: false, 
					orderable: false,
					render: (data, type, row, meta) => {
						let links = `<a href="/creatures/${row.id}" data-toggle="tooltip" data-placement="bottom" title="View"><i class="far fa-eye"></i></a>`;
						if (row.canEdit) {
							links = links + `  <a href="/creatures/${row.id}/edit" data-toggle="tooltip" data-placement="bottom" title="Edit"><i class="far fa-edit"></i></a>`
						}
						if (row.canCopy) {
							links = links + `  <a href="/creatures/${row.id}/copy" data-method="patch" data-confirm="Are you sure you want to copy monster ${row.name}?" data-toggle="tooltip" data-placement="bottom" title="Copy"><i class="far fa-copy"></i></a>`
						}
						if (row.canDelete) {
							links = links + `  <a href="/creatures/${row.id}" data-method="delete" data-confirm="Are you sure you want to delete monster ${row.name}?" data-toggle="tooltip" data-placement="bottom" title="Delete"><i class="far fa-trash-alt"></i></a>`
						}
						return links;
					}
				}
			],
			order: [[1, 'asc']],
			bAutoWidth: false,
			responsive: {
        details: false
    	},
    	"columnDefs": [
		    { className: "open-details", "targets": "_all" }
		  ]
		});

		$('#my-monsters-table').on('click', 'td.open-details', function() {
			let tr = $(this).closest('tr');
			let tbody = $(this).closest('tbody');
			let row = myMonstersTable.row(tr);
			let trBgdClass = tr.attr('class');

			if ( row.child.isShown() ) {
				// This row is already open - close it
				//row.child("").hide();
				//tr.removeClass('shown');
				console.log('row already open)');
			}
			else if (row.data()) {
				let monsterId = row.data()['id'];
				$.ajax(`/creatures/${monsterId}`, {
					method: 'GET'
				})
					.done((data, status, xhr) => {
						// Open this row
						row.child(data).show();
						row.child().addClass(trBgdClass);
						createEditor('#app', window.creatureData, 'view', monsterId);
						$('#my-monsters-table tr').removeClass('shown');
						tr.addClass('shown');
					})
					.fail(() => {
						console.log(arguments);
					});
			}

			tbody.find('tr').each(function(idx, r) {
				if (r != tr) {
					let row = myMonstersTable.row(r);
					row.child.hide();
				}
			});
		});
	}


	if ($("#app").length > 0) {
		createEditor('#app', window.creatureData, $('#app').data('mode'), window.creatureId);
	} else if (window.allCreatureData) {
		window.allCreatureData.forEach(bc => createEditor("#book-creature-" + bc.id, bc.data, "show", bc.id));

	}

  $('body').on('show.bs.modal', '#report-modal', function() {
    $('#report_reason').val('');
    $('#report_comments').val('');
  });

	$('body').on('click', '#submit-report-btn', function() {
		let creatureId = window.creatureId;
    let reason = $('#report_reason').val();
    if (reason == "") {
      $("label[for='report_reason']").css({color: 'red'});
    }
		$.ajax(`/creatures/${creatureId}/reports`, {
			method: 'POST',
			data: {
				report: {
					reason: $('#report_reason').val(),
					comments: $('#report_comments').val()
        }
      }
		})
			.done((data, status, xhr) => {
				$('#report-modal').modal('hide');
				showNotice('Report filed.');
      })
			.fail(() => {
				console.log(arguments);
				showAlert('Error submitting report.')
			});
	});

  $('body').on('click', '.submit-btn', function() {
    $('#submit-creature-link').attr('href', $(this).data('submit-url'));
    return true;
  });

  $('body').on('click', '#submit-vote', function() {
    const categories = $(this).closest('.vote').find('.category');
		const votes = {};
		//const votes = $.map(categories.get(), function(c) { 
		//return new Array([$(c).data('value'), $(c).find('.vote-value.selected').data('value')]); 
		//});
		categories.each(function(i, el) { 
			votes[$(el).data('value')] = $(el).find('.vote-value.selected').data('value'); 
		});
    const comments = $(this).closest('.vote').find('.vote-comments').val();

		const voteUrl = $('.vote').data('url');
		$.ajax(voteUrl, {
      data: JSON.stringify({votes: votes, comments: comments == "" ? null : comments}),
			method: 'POST',
			contentType: 'application/json'
		})
			.done((data, status, xhr) => {
				$('.vote').replaceWith(data);
			})
			.fail(() => {
				console.log(arguments);
			});
    return false;
  });
});

