import {createEditor} from "creature_editor";
import './stylesheets/editor'

$(function() {
	if ($('#reported-table').length) {
		let reportedTable = $('#reported-table').DataTable();
		$('#reported-table').on('click', 'td', function() {
			let tr = $(this).closest('tr');
      let creatureId = tr.data('id');
			let tbody = $(this).closest('tbody');
			let row = reportedTable.row(tr);

			if ( row.child.isShown() ) {
				// This row is already open - close it
				row.child("").hide();
				tr.removeClass('shown');
			}
			else {
				$.ajax(`/creatures/${creatureId}`, {
					method: 'GET'
				})
					.done((data, status, xhr) => {
						// Open this row
						row.child(data).show();
            window.creatureId = creatureId;
						createEditor('#app', window.creatureData, 'view', creatureId);
						tr.addClass('shown');
					})
					.fail(() => {
						console.log(arguments);
					});
			}

			tbody.find('tr').each(function(idx, r) {
				if (r != tr) {
					let row = reportedTable.row(r);
					row.child.hide();
				}
			});
		});
  }

	if ($('#banned-table').length) {
		let bannedTable = $('#banned-table').DataTable();
		$('#banned-table').on('click', 'td', function() {
			let tr = $(this).closest('tr');
      let creatureId = tr.data('id');
			let tbody = $(this).closest('tbody');
			let row = bannedTable.row(tr);

			if ( row.child.isShown() ) {
				// This row is already open - close it
				row.child("").hide();
				tr.removeClass('shown');
			}
			else {
				$.ajax(`/creatures/${creatureId}`, {
					method: 'GET'
				})
					.done((data, status, xhr) => {
						// Open this row
						row.child(data).show();
            window.creatureId = creatureId;
						createEditor('#app', window.creatureData, 'view', creatureId);
						tr.addClass('shown');
					})
					.fail(() => {
						console.log(arguments);
					});
			}

			tbody.find('tr').each(function(idx, r) {
				if (r != tr) {
					let row = bannedTable.row(r);
					row.child.hide();
				}
			});
		});
  }
});
