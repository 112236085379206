import { update } from "lodash";

$(function() {
    $('#book-creatures-table').DataTable();

    const updateCharsRemaining = () => {
        var nameInBook = $("#contract_name_in_book");
        $("#num-chars-remaining").html(20 - nameInBook.val().length);
        if (nameInBook.val().length > 20) {
            $("#chars-remaining-msg").css({color: "red"})
        } else {
            $("#chars-remaining-msg").css({color: "black"})
        }
    }

    if ($("#contract_name_in_book").length) {
        updateCharsRemaining();

        $("#contract_name_in_book").on("keyup", updateCharsRemaining);
    }
})