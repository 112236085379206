import {createEditor} from "creature_editor";
import './stylesheets/editor'

$(function() {
  //$(".datetimepicker-input").datetimepicker();
  //$('.contest-monsters-table').dataTable();

  $('.datetimepicker-input').each(function() {
    let date = moment($(this).val(), 'MM-DD-YYYY hh:mm a').toDate();
    $(this).datetimepicker({date: date});
  });

  $('#all-creatures-table, #submitted-creatures-table, #nominated-creatures-table, #winner-creatures-table').each(function(idx, table) {
    let url = $(table).attr('id').split('-')[0];
    let contestId = $(table).data('contest-id');
    let ajaxUrl = `/contests/${contestId}/${url}.json`;
    let isUser = $(table).data('is-user');
    let showState = $(table).data('show-state');
    let showPrize = $(table).data('show-prize');
    let dataTable = $(table).DataTable({
      processing: true,
      serverSide: true,
      ajax: ajaxUrl,
      columns: [
        {data: 'level'},
        {data: 'name'},
        {data: 'submitted'},
        {data: 'status', visible: showState},
        {data: 'type'},
        {data: 'author'},
        {data: 'you', visible: isUser},
        {data: 'numUserVotes'},
        {data: 'avgUserVote'},
        {data: 'avgImperfectUserVote'},
        {data: 'numJudgeVotes'},
        {data: 'avgJudgeVote', visible: !isUser},
        {data: 'weightedVote', visible: !isUser},
        {data: 'prize', visible: showPrize},
        {
          searchable: false, 
          orderable: false,
          render: (data, type, row, meta) => {
            return `<a href="/creatures/${row.id}" data-toggle="tooltip" data-placement="bottom" title="View"><i class="far fa-eye"></i></a>`
          }
        }
      ],
      order: [[10, 'desc']],
      //responsive: true,
      bAutoWidth: false
    });

		$(table).on('click', 'td', function() {
			let tr = $(this).closest('tr');
			let tbody = $(this).closest('tbody');
      let row = dataTable.row(tr);
      if (row.data()) {
        let creatureId = row.data()['id'];

        if ( row.child.isShown() ) {
          // This row is already open - close it
          row.child("").hide();
          tr.removeClass('shown');
        }
        else {
          $.ajax(`/creatures/${creatureId}`, {
            method: 'GET'
          })
            .done((data, status, xhr) => {
              // Open this row
              row.child(data).show();
              window.creatureId = creatureId;
              createEditor('#app', window.creatureData, 'view', creatureId);
              tr.addClass('shown');
            })
            .fail(() => {
              console.log(arguments);
            });
        }

        tbody.find('tr').each(function(idx, r) {
          if (r != tr) {
            let row = dataTable.row(r);
            row.child.hide();
          }
        });
      }
		});
  });

  $(document).on('ajax:success', '.btn-nominate', function(evt) {
    $('#all-creatures-table').DataTable().ajax.reload()
    $('#submitted-creatures-table').DataTable().ajax.reload()
    $('#nominated-creatures-table').DataTable().ajax.reload()
  });

  $(document).on('ajax:success', 'form.award-prize', function(evt) {
    $('#all-creatures-table').DataTable().ajax.reload()
    $('#nominated-creatures-table').DataTable().ajax.reload()
    $('#winner-creatures-table').DataTable().ajax.reload()
  });

	if ($('#current-monsters-table').length) {
    let currentMonstersTable = $('#current-monsters-table').DataTable({
      paging: false,
			bAutoWidth: false,
			responsive: {
			  details: false
      },
      columnDefs: [
        {
          targets: 7,
          orderable: false,
          searchable: false
        },
        {
          targets: 0,
          visible: false
        }
      ]
    });

    if (!$('#current-monsters-table').data('is-user')) {
      currentMonstersTable.column(5).visible(false);
    }

		$('#current-monsters-table').on('click', 'td.open-details', function(evt) {
			let tr = $(this).closest('tr');
			let tbody = $(this).closest('tbody');
			let row = currentMonstersTable.row(tr);
			let trBgdClass = tr.attr('class');

			if ( row.child.isShown() ) {
				// This row is already open - close it
				row.child("").hide();
				tr.removeClass('shown');
			}
			else {
				let creatureId = $(tr).data('id');
				$.ajax(`/creatures/${creatureId}`, {
					method: 'GET'
				})
					.done((data, status, xhr) => {
						// Open this row
						row.child(data).show();
						row.child().addClass(trBgdClass);
            window.creatureId = creatureId;
						createEditor('#app', window.creatureData, 'view', creatureId);
						$('#current-table tr').removeClass('shown');
						tr.addClass('shown');
					})
					.fail(() => {
						console.log(arguments);
					});
			}

			tbody.find('tr').each(function(idx, r) {
				if (r != tr) {
					let row = currentMonstersTable.row(r);
					row.child.hide();
				}
			});
		});
  }

  if ($("#winnersTab").length >0 && window.location.hash != "") {
    var tab  = window.location.hash;
    $(`a[href='${tab}']`).trigger("click");
  }

  if ($('.winnersTabContent').length > 0) {
    // $('.winnersTabContent').each(function(i, el) {
    //   createEditor('#app', window.creatureData, 'view');
    // })
    $('.grand-winner').each(function(i, el) {
      var creatureId = $(el).data("creature-id");
      createEditor("#grand-winner-" + creatureId, window.creatureData[creatureId], 'view');
    });

    $('.winnersTabContent').on('click', '.show-winner', function() {
      let creatureId = $(this).data('creature-id');
      let link = this;
      $.ajax(`/creatures/${creatureId}`, {
        method: 'GET'
      })
        .done((data, status, xhr) => {
          // Open this row
          $(link).closest('.tab-pane').find('.winner-box-body').html(data);
          window.creatureId = creatureId;
          createEditor('#app', window.creatureData, 'view', creatureId);
          let winnerBox = $(link).closest('.tab-pane').find('.winner-box');
          winnerBox.find('.editor').next('.row').remove();
          winnerBox.fadeIn('slow');
          winnerBox.get(0).scrollIntoView();
        })
        .fail(() => {
          console.log(arguments);
        });
    });

    $('.winnersTabContent').on('click', '.winner-box-close', function() {
      $(this).closest('.winner-box').hide();
      return false;
    });
  }
});
