$(function() {
  if ($('#users-table').length) {
    $('#users-table').dataTable({
      processing: true,
      serverSide: true,
      ajax: '/users.json',
      columns: [
        {data: 'first'},
        {data: 'last'},
        {data: 'role'},
        {data: 'editor'},
        {data: 'email'},
        {data: 'username'},
        {data: 'created'},
        {
          searchable: false, 
          orderable: false,
          render: (data, type, row, meta) => {
            let links = `<a href="/users/${row.id}" data-toggle="tooltip" data-placement="bottom" title="View"><i class="far fa-eye"></i></a>`;
            links = links + `  <a href="/users/${row.id}/edit" data-toggle="tooltip" data-placement="bottom" title="Edit"><i class="far fa-edit"></i></a>`
            return links;
          }
        }
      ],
      order: [[1, 'asc']],
      responsive: true,
      bAutoWidth: false
    });
  }
});
