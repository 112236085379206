$(function() {
  $('.creature_traits select').select2({
    maximumSelectionLength: 5,
    tags: true,
    tokenSeparators: [',', ' '],
    theme: 'bootstrap4'
  });

  $('.creature_languages select').select2({
    tags: true,
    tokenSeparators: [',', ' '],
    theme: 'bootstrap4'
  });

  if ( $('.faq-nav').length ) {
    $('.faq-nav li a').click(function() { 
      $('.faq-nav li a').removeClass('active');
      $(this).addClass('active');
    }); 
  }
  
  // RSS Feed to pull latest two RFC episodes
  if ( $('#rss-feeds').length ) {
    $(document).on("click", ".rss-wrapper .close-icon", function() {
      $('.rss-wrapper').hide();
    });
  };

  // Twitter & FB sharing
  if ( $('.share').length ) {
    $('.share').each(function() {
      var shareText, 
        shareUrl = window.location.href,
        uri = window.location.href,
        twitterShare = $(this).find('span.twitter'),
        facebookShare = $(this).find('span.facebook');

      // check if we're on the blog page with articles b/c we want to share links directly to each article for this page
      if ($(this).closest('article').length) {
        // find title of article
        shareText = $(this).closest('article').find('h2').text();
        // remove any existing hash in the URL
        if (window.location.hash != '') {
          uri = window.location.href.split("#")[0];
        } 
        // find the article ID and use as the new hash in the share URL
        // %23: URL encoding #
        var articleId = $(this).closest('article').prop('id');
        shareUrl = uri + '%23' + articleId;
      } 

      // twitter
      twitterShare.click(function() {
        var twitterWindow = window.open('https://twitter.com/intent/tweet?text=' + shareText + ' ' + shareUrl + ' via @rollforcombat', 'twitter-popup', 'height=350,width=600');
        if(twitterWindow.focus) { twitterWindow.focus(); } 
      });
      
      // fb (does not allow pre-filled text)
      facebookShare.click(function() {
        var facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + shareUrl, 'facebook-popup', 'height=350,width=600');
        if(facebookWindow.focus) { facebookWindow.focus(); }
      });

    });
  };

})
