import Vue from "vue"
import {Images, appdata} from "data"
import vuedraggable from "vuedraggable"
import DOMPurify from "dompurify"
import showdown from "showdown"
import _ from "lodash"
import Filter from "bad-words"

var start = new Date().getTime();
var elapsed = function () {
    return new Date().getTime() - start;
}

var util = {
    id: function () {
        return '_' + (
            Number(String(Math.random()).slice(2)) +
            Date.now() +
            Math.round(performance.now())
        ).toString(36);
    }
}

var monster = function () {
    this.id = util.id();
    this.name = '';
    this.traits = '';
    this.level = 0;
    this.specials = [];
    this.morespells = [];
    this.alignment = 'n';
    this.size = 'medium';
    this.type = 'humanoid';
    this.elements = [];
    this.adjust = 0;
    this.description = '';
    this.artdirection = '';
    this.items = '';
    this.spellattack = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.spelldc = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.savenote = '';
    this.creature = '';
    this.speed = '';
    this.imgurl = '';
    this.spelltype = '';
    this.ritualtype = '';
    this.rituals = '';
    this.spells = ['', '', '', '', '', '', '', '', '', '', ''];
    this.constant = '';
    this.strength = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.dexterity = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.constitution = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.intelligence = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.wisdom = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.charisma = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.perception = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.acrobatics = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.arcana = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.athletics = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.crafting = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.deception = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.diplomacy = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.intimidation = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.medicine = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.nature = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.occultism = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.performance = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.religion = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.society = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.stealth = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.survival = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.thievery = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.lore = {
        value: '',
        benchmark: 'moderate',
        note: '',
        name: 'lore'
    };
    this.lorealt = {
        value: '',
        benchmark: 'moderate',
        note: '',
        name: 'lore'
    };
    this.ac = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.hp = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.fortitude = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.reflex = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.will = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.resistance = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.weakness = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.immunity = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.roadmap = '';
    this.strikes = [];
    this.hints = ['perception', 'strength', 'dexterity', 'constitution', 'intelligence', 'wisdom', 'charisma', 'ac', 'fortitude', 'reflex', 'will', 'hp'];
    this.wordCount = 0;
}

var monsterspecial = function () {
    this.id = util.id();
    this.name = "Ability";
    this.traits = '';
    this.range = '';
    this.actions = 'one';
    this.type = 'offense';
    this.description = '';
}

var monsterstrike = function () {
    this.id = util.id();
    this.name = "Strike";
    this.traits = '';
    this.attack = '';
    this.damage = '';
    this.type = 'Melee';
}

var monsterspell = function () {
    this.id = util.id();
    this.name = '';
    this.note = '';
    this.spellattack = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.spelldc = {
        value: '',
        benchmark: 'moderate',
        note: ''
    };
    this.spells = ['', '', '', '', '', '', '', '', '', '', ''];
    this.constant = '';
}

Vue.component('dropselect', {
    props: ['init', 'action', 'options', 'value', 'button', 'optkey', 'optval'],
    template: `
    <div class="dropdown">
        <button class="btn text-uppercase w-100 border-gray" :class="button || 'btn-light'" data-toggle="dropdown"><span :class="{'text-extreme': value == 'extreme', 'text-high': value == 'high', 'text-moderate': value == 'moderate', 'text-low': value == 'low', 'text-terrible': value == 'terrible','text-abysmal': value == 'abysmal'}">{{ $root.getObj(value)[optval] || value || init || '&nbsp;' }}</span></button>
        <div class="dropdown-menu p-1 shadow-sm w-100 text-right" style="min-width: 40px">
            <slot></slot>
            <div class="dropdown-divider" v-if="$slots.default"></div>
            <button class="btn py-1 px-05 w-100 btn-light text-uppercase text-right" @click="$emit('input', opt[optkey] || opt)" v-for="opt of options">{{ opt[optval] || opt }}</button>
        </div>
    </div>
    `
})

Vue.component('multiselect', {
    props: ['init', 'action', 'options', 'value', 'button', 'optkey', 'optval'],
    data() {
        return {
            selected: this.init || []
        }
    },
    methods: {
        selectOption(opt) {
            let index = this.selected.indexOf(opt)
            if (index !== -1) {
                this.selected.splice(index, 1)
            } else {
                this.selected.push(opt)
            }
            this.$emit('input', this.selected)
        }
    },
    template: `
      <div class="dropdown">
        <button class="btn text-left text-capitalize w-100 border-gray" :class="button || 'btn-light'" data-toggle="dropdown">
          <span class="px-1">
            {{ this.selected.join(', ') }}
          </span>
        </button>
        <div class="dropdown-menu p-1 shadow-sm w-50 text-right" style="min-width: 40px">
          <slot></slot>
          <div class="dropdown-divider" v-if="$slots.default"></div>
          <button class="btn py-1 px-05 w-100 btn-light text-capitalize text-right" @click="selectOption(opt)" v-for="opt of options">
            <div class="d-inline-block" v-if="selected.includes(opt)">
              <i class="fas fa-check"></i>
            </div>
            {{ opt[optval] || opt }}
          </button>
        </div>
      </div>
    `,
})


Vue.component('row', {
    template: `
    <div class="d-flex flex-wrap">
        <slot></slot>
    </div>
    `
})

Vue.component('row-col', {
    props: ['span'],
    computed: {
        col() {
            if (this.span)
                return 'col-' + this.span;
            return 'col';
        }
    },
    template: `
    <div :class="col" class="p-0">
        <slot></slot>
    </div>
    `
})

Vue.component('card', {
    props: ['header', 'no-ui'],
    computed: {
        isVisible() {
            if (!this.header)
                return true;

            return !this.$root.hidden.includes(this.header);
        },
        showComment() {
            if (!this.header)
                return false;

            return !this.$root.hidecomment.includes(this.header.toLowerCase());
        }
    },
    methods: {
        toggle() {
            if (!this.header)
                return;

            if (this.$root.hidden.includes(this.header)) {
                let i = this.$root.hidden.findIndex(a => a == this.header)
                this.$root.hidden.splice(i, 1);
            } else {
                this.$root.hidden.push(this.header);
            }
        },
        closeComment() {
            if (!this.header)
                return;
            this.$root.hidecomment.push(this.header.toLowerCase());
        }
    },
    template: /*html*/`
    <div class="card">
        <div class="card-header py-1 text-gray" v-if="header">
        <div class="d-flex" v-if="header">
            <h3 class="text-uppercase" style="opacity: 0.5">{{ header || '&nbsp;'}}</h3>
            <h3 class="ml-auto pointer btn-light text-muted" @click="toggle" v-if="noUi === undefined">
                <i v-show="isVisible" class="fas fa-fw fa-minus"></i>
                <i v-show="!isVisible" class="fas fa-fw fa-expand"></i>
            </h3>
            </div>
        </div>
        <div class="card-body py-1" v-show="isVisible">
            <template  v-if="header">
            <div class="p-2 mb-1 rounded" style="background: #eee" v-if="$root.getComment(header.toLowerCase()) && showComment">
            <a class="px-0 float-right pointer" @click="closeComment"><i class="fas fa-times"></i></a>
            <div class="font-weight-bold text-uppercase mb-1">{{ $root.target.roadmap }}</div>
            <div class="pl-3">{{ $root.getComment(header.toLowerCase()) }}</div>
            </div>
            </template>
            <slot></slot>
        </div>
    </div>
    `
})

Vue.component('fa', {
    props: ['icon', 'fw', 's', 'r', 'd', 'l', 'w'],
    computed: {
        set() {
            if (this.r !== undefined)
                return 'far'
            if (this.d !== undefined)
                return 'fad'
            if (this.l !== undefined)
                return 'fal'
            return 'fas'
        }
    },
    template: /*html*/ `
<i :style="{'w': w + 'px'}" :class="[{'fa-fw': fw !== undefined},'fa-' + icon, set]"></i>
    `
});

Vue.component('modal', {
    props: ['title', 'id', 'mw', 'subtype', 'static'],
    template: /*html*/ `
<div class="modal fade" tabindex="-1" role="dialog" :id="id">
    <div class="modal-dialog shadow" role="document" :style="{'max-width': mw + 'px'}">
        <div class="modal-content">
            <div class="modal-header d-flex align-items-center">
                <h5 class="modal-title text-uppercase op-50 flex-grow-1">{{ title }}</h5>
                <template v-if="static === undefined">
                <button class="btn btn-light-h340" data-dismiss="modal" aria-label="Close">
                    <fa fw icon="times"/>
                </button>
                </template>
            </div>
            <div class="modal-body">
                <slot></slot>
            </div>
        </div>
    </div>
</div>
    `
});

Vue.component('action', {
    props: ['act'],
    template: `
        <div class="d-inline-block" style="height: 1em; text-indent:0" v-if="act != 'none' && act != '10 minutes' && act != '1 minute'">
            <img :src="Images.a" v-if="act == 'one'" class="text-img">
            <img :src="Images.aa" v-if="act == 'two'" class="text-img">
            <img :src="Images.aaa" v-if="act == 'three'" class="text-img">
            <img :src="Images.f" v-if="act == 'free'" class="text-img">
            <img :src="Images.r" v-if="act == 'reaction'" class="text-img">
        </div>
    `
});

Vue.prototype.Images = Images;
Vue.prototype.window = window;

const converter = new showdown.Converter({
  simpleLineBreaks: true
});

Vue.component('account', {
    data() {
        return {
            confirm: '',
        }
    },
    template: /*html*/ `
<div>
    <div class="p-2">
        <h3>Entries</h3>
        <div v-for="i of $root.monsters">
        {{i.name || 'Monster'}}
        </div>
    </div>
   
    <div class="p-2">
        <h3>Delete</h3>
        <div class="d-flex align-items-center">
        <div class="flex-even">Type 'delete' to delete your account.</div>
        <div class="flex-even"><input class="form-control text-uppercase" v-model="confirm"></div>
        <div class="flex-even"><button v-if="confirm.toLowerCase() == 'delete'" class="btn btn-outline-primary text-uppercase font-weight-bold w-100" @click="$root.terminateData">delete</button></div>
        </div>
    </div>
j</div>
`
});

Vue.component('share', {
    template: /*html*/ `
<div>
    <div class="d-flex align-items-center">
    <div class="flex-even"><div class="px-2 small font-weight-bold text-center" v-if="$root.target.meta.public"><a target="_blank" :href="window.location.origin + '/v/' + $root.target.meta.url ">{{ window.location.origin + '/v/' + $root.target.meta.url }}</a></div></div>
    <div class="flex-even"><button class="btn btn-outline-secondary opacity-25 text-uppercase font-weight-bold w-100" :class="{'active': $root.target.meta.public}" @click="$root.target.meta.public = 1"><fa fw icon="eye"/> sharing enabled</button></div>
    <div class="flex-even"><button class="btn btn-outline-gray opacity-25 text-uppercase font-weight-bold w-100" :class="{'active': !$root.target.meta.public}" @click="$root.target.meta.public = 0"><fa fw icon="eye-slash"/> sharing disabled</button></div>
    </div>
    <div class=" p-2 line-height-120" style="opacity: 0.75" v-html="$root.md($root.text['share'])" ></div>
</div>
    `
})

export function createEditor(element, creatureData, mode, creatureId) {
  var vm = new Vue({
    el: element,
    data: {
      appdata: appdata,
      monsters: [creatureData || new monster()],
      creatureId: creatureId,
      modalId: '',
      editid: '',
      isLoaded: false,
      text: {},
      mode: mode,
      hidden: ['Data', 'Additional Spells'],
      urlpath: '',
      hidecomment: [],
      pathname: window.location.pathname
    },
    watch: {
      monsters: {
        handler(v) {
          this.saveData();
          document.title = this.target.name || 'PF2e Monster Tool';

          // document.title = this.target.name || 'PF2e Monster Tool';
          // if (this.mode != 'edit')
          //     return;
          // if (elapsed() < 2000)
          //     return;
          // if (newVal) {
          //     localStorage.setItem('data', JSON.stringify(newVal));
          //     console.log("-- Saved changes to local storage.");
          // }
        },
        deep: true
      },
      target(newVal) {
        this.hidecomment = [];
        localStorage.setItem('open', this.editid);
        document.title = this.target.name || 'PF2e Monster Tool';
      }
    },
    computed: {
      online() {
        return false;
      },
      target() {
        return this.monsters.find(m => m.id == this.editid) || this.monsters[0];
      },
      cantrip() {
        let lv = Math.ceil(Math.max(this.target.level, 2) / 2)
        return lv + this.numberSuffix(lv);
      }
    },
    methods: {
      modal(id) {
        if (!id) {
          $('#modal').modal('hide');
          return;
        }
        this.modalId = id;
        $('#modal').modal();
      },
      terminateData() {
        let self = this;
        $.post('/r', {
          t: _token,
          r: 'terminate',
        }, function (data) {
          window.location.href = window.location.origin + '/logout.php';
        });
      },
      saveData: _.debounce(function () {
        if (!this.isLoaded) {
          this.isLoaded = true;
          return;
        }

        // if (this.route != 'edit')
        //     return;

        // if (this.isGuest) {
        //     localStorage.setItem('data', JSON.stringify(this.entries));
        // }
        // else {
        if (this.mode != 'edit')
          return;

        if (this.online) {
          let e = JSON.stringify(this.target);
          $.post('/r/', {
            t: _token,
            r: 'save',
            data: e
          }, function (data) {
            console.log('-- Entry saved remotely');
          })
        }
        else {
          if (!localStorage['backup-10']) {
            localStorage.setItem('backup-10', localStorage['data']);
          }
          localStorage.setItem('data', JSON.stringify(this.monsters));
          console.log('-- Entry saved in local storage');
        }
      }, 250),
      createNew() {
        if (this.online) {
          this.newFile();
        }
        else {
          let nm = new monster();
          this.monsters.push(nm);
          this.editid = nm.id;
        }
      },
      newSpecial() {
        let sp = new monsterspecial();
        this.target.specials.push(sp);
      },
      removeSpecial(id) {
        let index = this.target.specials.findIndex(sp => sp.id == id);
        if (index >= 0)
          this.target.specials.splice(index, 1);
      },
      newSpells() {
        if (!this.target.morespells)
          this.target.morespells = [];
        this.target.morespells.push(new monsterspell());
      },
      removeSpells(id) {
        let index = this.target.morespells.findIndex(sp => sp.id == id);
        if (index >= 0)
          this.target.morespells.splice(index, 1);
      },
      nonEmptySpells(spells) {
        return spells.map((sp, i) => {
          return { level: i, spell: sp }
        }).filter(sp => sp.spell);
      },
      removeMonster() {
        let self = this;
        if (this.online) {
          $.post('/r/', {
            t: _token,
            r: 'delete',
            file: self.target.id
          }, function (data) {
            if (data.success) {
              let id = self.target.id;
              self.monsters = self.monsters.filter(e => e.id != id);
              self.modal('');
              if (!self.monsters.length) {
                self.mode = '';
                self.newFile();
              }
              else {
                self.editid = '';
              }
            }
          });

        }
        else {
          let index = this.monsters.findIndex(m => m.id == this.target.id);
          this.monsters.splice(index, 1);

          if (this.monsters.length == 0)
            this.createNew();
          else
            this.editid = this.monsters[0].id;
        }
      },
      onDrop: function (e) {
        let self = this;
        // console.log(e.dataTransfer.files)
        let file = e.dataTransfer.files[0];
        if (!file)
          return;
        if (!file.type.match('json.*')) {
          alert('Must be json data');
          return;
        }
        let fr = new FileReader();
        fr.onload = function (e) {
          try {
            let data = JSON.parse(e.target.result);
            if (self.online) {
              delete data.id;
              delete data.meta;
              delete data.hints;
              self.newFile((obj) => {
                _.merge(obj, data);
              });
            }
            else {
              data.id = util.id();
              self.monsters.push(_.merge(new monster(), data));
              self.monsters.splice();
              self.editid = data.id;
              console.log("-- Uploaded");
            }

          } catch (e) {
            console.log("-- Error");
            console.log(e);
          }
          // window.location.reload(true);
          // try {
          //     let data = JSON.parse(e.target.result);
          //     data.id = util.id();
          //     self.monsters.push(data);
          //     self.editid = data.id;
          //     console.log("-- Uploaded");

          // } catch (e) {
          //     console.log("-- Error");
          //     console.log(e);
          // }
        };
        fr.readAsText(file);
      },
      newStrike() {
        let st = new monsterstrike();
        this.target.strikes.push(st);
      },
      removeStrike(id) {
        let index = this.target.strikes.findIndex(st => st.id == id);
        if (index >= 0)
          this.target.strikes.splice(index, 1);
      },
      getObj(search, key = 'id') {
        return this.monsters.find(m => m[key] == search) || {}
      },
      splitCommas(str) {
        if (!str)
          return []
        return str.split(/,+\s*/gi).map(s => s.trim().toLowerCase());
      },
      getHintObj(key) {
        return this.appdata[key].find(h => h.level == this.target.level);
      },
      getHint(key, val = '', ensuresign = true) {
        val = val || key;
        let result = 0;
        if (!this.target[val]) {
          if (this.appdata[key])
            result = this.appdata[key].find(h => h.level == this.target.level)[val] || 0;
        }
        else {
          result = this.appdata[key].find(h => h.level == this.target.level)[this.target[val].benchmark] || 0;
        }
        if (ensuresign)
          result = this.ensureSign(result);
        return result;
      },
      ensureSign(obj) {
        let str = obj + '';
        if (str.includes('-') || str.includes('+'))
          return str;
        return '+' + str;
      },
      sortSkills(a, b) { 
        let one = (this.target[a].name || a).toUpperCase();
        let two = (this.target[b].name || b).toUpperCase();
        if (one < two) {
          return -1;
        } else if (two < one) {
          return 1;
        } else {
          return 0;
        }
      },
      titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
          // You do not need to check if i is larger than splitStr length, as your for does that for you
          // Assign it back to the array
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
      },
      numberSuffix(num) {
        if (num == '1') return 'st';
        if (num == '2') return 'nd';
        if (num == '3') return 'rd';
        return 'th'
      },
      getComment(key) {
        if (!this.target.roadmap)
          return '';
        return this.appdata.roadmap[this.target.roadmap].comment[key] || ''
      },
      startsWithBold(str) {
        if (!str) return false;
        return this.formatAll(str).startsWith('<b');
      },
      formatAll(str, handleKeywords = true) {
        if (!str) return '';

        let self = this;
        str = str.replace(/\%/g, this.target.name);
        str = str.replace(/\[(.*)\]/gi, function (match, p1) {
          p1 = self.appdata.name[p1] || p1;
          if (self.target[p1])
            return self.target[p1].value || '';
          return "[" + p1 + "]";
        });
        str = str.replace(/(\([^\(]+\))/g, '<span class="font-normal font-weight-normal">$&</span>')
                .replace(/(\*\*[^\*]+\*\*)/g, '<span class="font-normal font-weight-normal">$&</span>')
        if (handleKeywords) {
          str = str.replace(/(Saving Throw|Maximum Duration|Stage 1|Stage 2|Stage 3|Stage 4|Stage 5|Stage 6|Trigger|Effect|Critical Success|Success|Failure|Critical Failure|Requirements|Requirement|Frequency|\(1st\)|\(2nd\)|\(3rd\)|\(4th\)|\(5th\)|\(6th\)|\(7th\)|\(8th\)|\(9th\)|\(10th\))/g, '<b class="font-normal">$&</b>')
        }
        str = str.replace(/\(x(\d+)\)/g, '(&times;$1)')
          .replace(/\*\*(.+?)\*\*/g, '<b>$1</b>')
          .replace(/\_(.+?)\_/g, '<i>$1</i>')
          .replace(/\*/g, '•')
          .replace(/\((a|aa|aaa|r|f|1a|2a|3a)\)/gi, (m, p1) => {
            return `<img class="text-img" src="${Images[p1.toLowerCase()]}">`
          })
          return DOMPurify.sanitize(str);
        },
      useHint(hint, key, ensuresign = true) {
        key = key || hint;
        let h = this.getHint(hint, key, ensuresign);
        this.target[key].value = h;
      },
      md(str) {
        if (!str) return '';
        let self = this;
        str = str.replace(/\%/g, this.target.name);
        str = str.replace(/\[(.*)\]/gi, function (match, p1) {
          p1 = self.appdata.name[p1] || p1;
          if (self.target[p1])
            return self.target[p1].value || '';
          return '';
        });
        return converter.makeHtml(DOMPurify.sanitize(str))
      },
      downloadMonster() {
        let t = JSON.parse(JSON.stringify(this.target));
        delete t.meta;
        delete t.hints;
        delete t.id;
        this.downloadJson(JSON.stringify(t), this.target.name + '-' + (Math.floor(new Date().getTime() / 1000)));
      },
      makeMonster(str) {
        let self = this;
        let data = str;
        if (typeof str === "string")
          data = JSON.parse(data);
        if (self.online) {
          delete data.id;
          delete data.meta;
          delete data.hints;
          self.newFile((obj) => {
            _.merge(obj, data);
          });
        }
        else {
          data.id = util.id();
          self.monsters.push(_.merge(new monster(), data));
          self.monsters.splice();
          self.editid = data.id;
          console.log("-- Uploaded");
        }
      },
      uploadMonster() {
        let self = this;
        this.uploadJson(function (e) {
          try {
            self.makeMonster(e.target.result);
          } catch (e) {
            console.log("-- Error");
            console.log(e);
          }
          // window.location.reload(true);
        })
      },
      publishJson() {

      },
      saveCopy() {
        return;
        let monster = this.monsters[0];
        monster.id = util.id();

        this.loadData();
        this.monsters.push(monster);

        this.editid = monster.id;
        this.mode = 'edit';

        history.pushState({}, "", window.location.origin)
      },
      downloadJson(json, exportName) {
        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(json);
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName.replace(/\W/gi, '') + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      },
      uploadJson(onload) {
        let self = this;
        let file = $('input[type=file]');
        file.one('input', function () {
          let files = file.prop('files');
          let fr = new FileReader();
          fr.onload = onload;
          fr.readAsText(files.item(0));
          file.val(null);
        });
        file.val(null).click();
      },
      saveToServer() {
        let filter = new Filter();
        this.target.name = filter.clean(this.target.name);
        let creaturesUrl = window.creaturesBaseUrl || '/creatures';
        let saveUrl = creaturesUrl;
        let saveMethod = 'POST';

        if (this.creatureId) {
          saveUrl = creaturesUrl + '/' + this.creatureId;
          saveMethod = 'PUT';
        }

        $.ajax(saveUrl, {
          data: JSON.stringify({creature: {data: this.target}}),
          method: saveMethod,
          contentType: 'application/json' 
        })
          .done((evt, status, xhr) => {
            window.location.href = creaturesUrl + '/' + xhr.getResponseHeader('X-Creature-Id');
          })
          .fail(() => {
            console.log(arguments);
          })
      },
      getLeft(str) {
        return str.split('-')[0];
      },
      getRight(str) {
        return str.split('-')[1];
      },
      getPdf() {
        html2pdf(document.getElementById('monster-output'), {
          margin: 10,
          filename: this.target.name.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '-' + (Math.floor(new Date().getTime() / 1000)) + '.pdf',
          html2canvas: { scale: 2, useCORS: true },
          image: { type: 'jpeg', quality: 0.8 }
        });
      },
      getHtml() {
        let result = '<html><head><style>' + cssdata + '</style></head><body><div class="btn-select" style="width: 720px; margin: 1rem auto">';
        result += DOMPurify.sanitize(document.getElementById('monster-output').innerHTML);
        result += '</div></body></html>'

        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(result));
        element.setAttribute('download', this.target.name.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '-' + (Math.floor(new Date().getTime() / 1000)) + '.htm');

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

      },
      syncAllHints(filter = true) {
        for (let datakey in this.appdata.hint) {
          for (let k of this.appdata.hint[datakey]) {
            if (!this.target.hints.includes(k) && filter)
              continue;

            let h = this.getHintObj(datakey);
            let v = h[this.target[k].benchmark];
            if (datakey != 'ac' && datakey != 'dc')
              v = this.ensureSign(v);
            this.target[k].value = v;

            console.log(`-- Applied hint ${v} to ${k}`)
          }
        }
        if (this.target.hints.includes('hp')) {
          let hpmin = parseInt(this.getRight(this.getHint('hp'))) || 1;
          let hpmax = parseInt(this.getLeft(this.getHint('hp'))) || 1;
          let hpnum = Math.floor((hpmin + hpmax) / 2);
          this.target.hp.value = hpnum;
          console.log(`-- Applied hint ${hpnum} to hp`)
        }
        $('.show').removeClass('show');
      },
      toggleHint(id) {
        if (!this.target.hints) {
          this.target.hints = [];
        }
        if (this.target.hints.includes(id)) {
          this.target.hints.splice(this.target.hints.indexOf(id), 1);
        } else {
          this.target.hints.push(id);
        }
      },
      hasSync(id) {
        if (!this.target.hints) {
          return false;
        }
        return this.target.hints.includes(id);
      },
      trimParens(str) {
        return str.replace(/ \(\d+\)/gi, '');
      },
      replaceDice(str, dice) {
        if (!str.match(/\d+d\d+\+?\d*/))
          return dice;

        return str.replace(/\d+d\d+\+?\d*/, dice);
      },
      replaceDamageType(str, type) {
        if (!str.match(/piercing|slashing|bludgeoning/i))
          return str + ' ' + type;
        return str.replace(/(piercing|slashing|bludgeoning)/, type);
      },
      getMonsterText() {
        let t = this.target;
        let result = '';
        result += `${t.name} (${t.creature} ${t.level})\n`
        result += `${t.alignment}, ${t.size}, ${t.traits}\n\n`
        for (let datakey in ['perception', 'skill', 'ability', 'ac', 'save']) {
          for (let key of this.appdata.hint[datakey]) {
            result += `${this.titleCase(key)}: ${t[key].value}`
            if (t[key].note) {
              result += ` (${t[key].note})`;
            }
            result += '\n';
          }
          result += '\n';
        }
        if (t.savenote)
          result += `(${t.savenote})\n`
        for (let datakey in ['hp', 'immunity', 'resistance', 'weakness']) {
          for (let key of this.appdata.hint[datakey]) {
            result += `${this.titleCase(key)}: ${t[key].value}`
            if (t[key].note) {
              result += ` (${t[key].note})`;
            }
            result += '\n';
          }
          result += '\n';
        }

        return result;
      },
      clearRoadmap() {
        this.target.roadmap = '';
      },
      newFile(onDone) {
        let self = this;
        if (this.online) {
          $.post('/r/', {
            t: _token,
            r: 'new'
          }, function (data) {
            if (data.success) {
              let v = data;
              let m = new monster();
              m.id = '';
              let obj = _.merge({}, m, v.data);
              obj.meta = v.meta;
              if (onDone) {
                onDone(obj);
              }
              self.monsters.push(obj);
              if (self.monsters.length) {

                self.mode = 'edit';
                self.editid = obj.id;
              }
            }
          })
        }
      },
      loadData() {
        for (let m of this.monsters) {
          if (!m.hints) {
            m.hints = ['perception', 'strength', 'dexterity', 'constitution', 'intelligence', 'wisdom', 'charisma', 'ac', 'fortitude', 'reflex', 'will', 'hp']; // Update to data structure
          }
        }
      },
      viewData() {
        let self = this;
        $.post('/r', {
          t: _token,
          r: 'view',
          v: _v
        }, function (data) {
          if (data.success) {
            let monsters = [];
            let v = data;
            let m = new monster();
            m.id = '';
            let obj = _.merge({}, m, v.data);
            obj.meta = v.meta;
            monsters.push(obj);
            self.monsters = monsters;
            self.mode = 'view';
            self.editid = obj.id;
          }
        })
      },
      goToJson(url) {
        let self = this;

        if (!url)
          return;
        url = url.replace('https://', '')
          .replace('http://', '');
        console.log(url);
        if (url.match(/^2e\.aonprd\.com\/Monsters.aspx\?ID=\d+/gmi)) {
          console.log("-- Loading AoN Monster");
          $.post('/r', {
            t: _token,
            url: url,
            r: 'url'
          }, function (data) {
            if (data.success) {
              if (data.mode == 'edit') {
                self.makeMonster(data.data);
              }

            }
            else {
              console.log("-- Loading Failed!");
            }
          })

          return;
        }
        if (!url.endsWith('.json'))
          return;
        window.location.href = window.location.origin + '/' + url.replace(/https?:\/\//gmi, '')
      },
      loadRoadmap(key) {
        let t = this.target;
        let rm = this.appdata.roadmap[key];
        this.hidecomment = [];

        t.roadmap = key;
        let wiped = false;

        for (let k in rm.benchmark) {
          let benchmark = rm.benchmark[k][Math.floor(Math.random() * rm.benchmark[k].length)];
          if (benchmark == '')
            continue;

          t[k].benchmark = benchmark.split('-')[0];
          let modifier = benchmark.split('-')[1] || 0;
          let type = k;
          if (this.appdata.label.skill.includes(k)) {
            type = 'skill'
            if (!wiped) {
              for (let sk of this.appdata.label.skill) {
                this.target[sk].value = '';
              }
              wiped = true;
            }
          }
          if (this.appdata.label.save.includes(k)) {
            type = 'save'
          }
          if (this.appdata.label.ability.includes(k)) {
            type = 'ability'
          }
          if (k == 'spelldc') {
            type = 'dc'
          }
          if (k == 'spellattack') {
            type = 'spell'
          }
          let hint = this.getHint(type, k, false) - modifier;
          t[k].value = this.ensureSign(hint);
          if (type == 'ac') {
            t[k].value = this.getHint(type, k, false)
          }
          if (type == 'hp') {
            hint = this.getHint(type, k, false);
            let hpmin = parseInt(this.getRight(hint)) || 1;
            let hpmax = parseInt(this.getLeft(hint)) || 1;
            t[k].value = Math.floor((hpmin + hpmax) / 2);
            console.log(`-- Applied hint ${t[k].value} to hp`)
          } else {
            console.log(`-- Applied hint ${hint} to ${k}`)
          }
        }

        console.log("-- Applied roadmap " + key);
      },
      countWords() {
        let outputText = $('#monster-output').text();
        this.target.wordCount = $.trim(outputText).split(/[\s]+/).length - 3;  // remove count for Word Count: X text itself
      }
    },
    components: {
      'draggable': vuedraggable
    },

    mounted() {
      //fetch('/md/share.md')
      //.then(r => r.text())
      //.then(data => {
      //self.text['share'] = data
      //self.text = JSON.parse(JSON.stringify(self.text)); // Vue hack
      //});

      //if (window.location.search.includes('code=')) {
      //window.history.replaceState('', '', window.location.origin);
      //}
      //if (window.location.search.includes('fbclid=')) {
      //window.history.replaceState('', '', window.location.origin + window.location.pathname);
      //}

      //let self = this;

      //if (_v) {
      //this.viewData();
      //}
      //else {
      //if (this.pathname.length < 3) {
      //this.pathname = '';
      //}

      //this.pathname = this.pathname.replace('/', '')
      //.replace('https://', '')
      //.replace('http://', '');

      //// let origin = window.location.origin.replace('/', '')
      ////     .replace('https://', '')
      ////     .replace('http://', '');

      //// if (this.pathname.startsWith(origin)) {
      ////     window.location.href = window.location.origin + window.location.pathname;
      //// }

      //if (this.pathname) {
      //this.mode = 'view';
      //console.log("-- Loading Path " + this.pathname);
      //$.post('/r', {
      //t: _token,
      //url: this.pathname,
      //r: 'url'
      //}, function (data) {
      //if (data.success) {
      //if (data.mode == 'edit') {
      //self.makeMonster(data.data);
      //}
      //else {
      //self.monsters = [data.data];
      //self.editid = self.monsters[0].id;
      //}
      //}
      //else {
      //console.log("-- Loading Failed!");
      //self.loadData();
      //self.mode = 'edit';
      //}
      //setTimeout(function () {
      //$('#loading').fadeOut(250, function () { $(this).remove() });
      //}, 250)
      //})
      //}
      //else {
      this.loadData();
      
      if (!this.target.wordCount) {
        this.countWords();
        this.$forceUpdate();
      }
      if (this.target.wordCount < 500 || this.target.wordCount > 600) {
        $('.word-count-msg').show();
        $('a.submit-btn').addClass('disabled').removeAttr('data-toggle').attr('href', 'javascript:void(0)');
      }
      if (this.target.name.trim() == "") {
        $('.name-msg').show();
        $('a.submit-btn').addClass('disabled').removeAttr('data-toggle').attr('href', 'javascript:void(0)');
      }
      if (!this.target.elements || this.target.elements.length < 1 || this.target.elements.length > 3) {
        $('.element-msg').show();
        $('a.submit-btn').addClass('disabled').removeAttr('data-toggle').attr('href', 'javascript:void(0)');
      }
      //}
      //}

      setTimeout(function () {
        $('#loading').fadeOut(250, function () { $(this).remove() });
      }, 50)

      //setInterval(function () {
      //// console.log('-- Ping?')
      //$.post('/r/', {
      //r: 'ping',
      //t: _token
      //}, function (data) {
      //// console.log('-- Pong!');
      //if (data == '...') {
      //console.log("-- Session Expired Error");
      //}
      //})
      //}, 1000 * 60 * 1);
    },
    template: /*html*/`
    <div class="editor" v-on:keyup="countWords">
    <!--<div id="loading"></div>-->
    <template v-if="mode">
    <div class="d-md-none" v-if="mode == 'edit'" style="z-index: 100">
        <div class="alert alert-danger p-1 font-italic small w-100 position-fixed">
            This is intended for desktop devices. Some things might not work on mobile.
        </div>
    </div>
    <div class="container-fluid" :class="[{'py-5': mode == 'edit'}]"><div class="row"><div :class="[{'col-md-12': mode == 'edit'}, {'col-md-12 col-lg-8 mx-auto': mode == 'view'}]">    
    <div id="dropzone" @dragover.prevent @drop.stop.prevent="onDrop">
        <div class="d-md-flex" :class="[{'vh-100': mode == 'edit'}]">
            <row-col class="overflow-auto" v-if="mode == 'edit'">
                <div class="cards" >
                    <card>
                        <div class="d-flex">
                            <div class="flex-fill">
                                <input class="form-control" v-model="target.name" placeholder="Name">
                            </div>
                            <div>
                                <div class="dropdown d-inline-block">
                                    <button data-show="popover" data-content="Apply Hints" class="btn btn-gray px-05" data-toggle="dropdown"><i class="fas fa-fw fa-sync-alt"></i></button>
                                    <div class="dropdown-menu dropdown-menu-right allow-focus shadow-sm p-1" style="width: 250px">
                                        <button class="btn w-100 btn-outline-secondary mb-1 text-uppercase text-center" @click="syncAllHints()"><i class="fas fa-fw fa-check"></i> Apply Hints</button>
                                        <template v-for="datakey in appdata.hint">
                                            <template v-for="k in datakey">
                                                <button class="btn btn-sm w-50 btn-light text-uppercase text-right" @click="toggleHint(k)" :class="{'text-gray': !hasSync(k)}">{{appdata.verbose[k] || k}} <i class="fas fa-fw fa-check" v-if="hasSync(k)"></i> <i class="far fa-fw fa-square" v-if="!hasSync(k)"></i></button>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                                <div class="dropdown d-inline-block">
                                    <button data-show="popover" data-content="Base Roadmaps" class="btn btn-gray px-05" data-toggle="dropdown"><i class="fas fa-fw fa-users-cog"></i></button>
                                    <div class="dropdown-menu dropdown-menu-right shadow-sm p-1" style="width: 250px">
                                        <div class="btn btn-sm pt-0 text-uppercase w-100 text-center text-muted">apply benchmarks and hints from roadmap</div>
                                        <button class="btn w-100 btn-light text-uppercase text-right text-gray mb-1" @click="clearRoadmap"><i class="fas fa-fw float-left fa-times"></i> remove notes</button>
                                        <button v-for="r,k of appdata.roadmap" class="btn w-100 btn-light text-uppercase text-right text-gray" @click="loadRoadmap(k)"><i class="fas fa-fw float-left" :class="['fa-' + r.icon]"></i> {{k}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <row class="mt-1">
                            <row-col>
                                <div id="create-monster-actions" v-if="mode == 'edit'">
                                    <button class="btn btn-secondary text-uppercase mr-1" @click="saveToServer">Save</button>
                                    <a href="/creatures/my" class="text-muted" data-confirm="Are you sure you want to discard your edits?">Cancel</a>
                                </div>
                            </row-col>
                        </row>
                    </card>
                    <card>
                        <row class="mt-1">
                            <row-col>
                                <label>level&nbsp;</label>
                                <dropselect init="0" :options="appdata.level" v-model="target.level"  data-show="popover" data-content="Creation Benchmark Level" >
                                </dropselect>
                            </row-col>
                            <row-col>
                                <label>alignment&nbsp;</label>
                                <dropselect init="n" :options="appdata.alignment" v-model="target.alignment">
                                </dropselect>
                            </row-col>
                            <row-col span="4">
                                <label>size</label>
                                <dropselect init="medium" :options="appdata.size" v-model="target.size">
                                </dropselect>
                            </row-col>
                            <row-col span="4">
                                <label>type</label>
                                <dropselect init="humanoid" :options="appdata.type" v-model="target.type">
                                </dropselect>
                            </row-col>
                            <row-col class="d-none">
                                <label>creature</label>
                                <input class="form-control text-uppercase" placeholder="CREATURE" v-model="target.creature">
                            </row-col>
                            <row-col style="max-width: 20px" class="d-none">
                                <label class="invisible">level</label>
                                <input data-show="popover" data-content="Display Level" class="form-control text-center" :placeholder="target.level" v-model.number="target.showlevel">
                            </row-col>
                            <row-col style="max-width: 30px">
                                <label>Pict</label>
                                <div class="dropdown text-center">
                                    <button data-show="popover" data-content="Monster Image URL" class="btn btn-gray px-05 w-100" data-toggle="dropdown"><i class="fas fa-fw fa-image"></i></button>
                                    <div class="dropdown-menu dropdown-menu-right shadow-sm p-1" style="width: 250px">
                                        <input class="form-control-sm form-control" placeholder="image url" v-model="target.imgurl">
                                    </div>
                                </div>
                            </row-col>
                        </row>
                        <row class="mt-1">
                            <row-col>
                                <label>traits</label>
                                <input class="form-control" placeholder="undead, zombie, etc, etc" v-model="target.traits">
                            </row-col>
                        </row>
                        <row class="mt-1">
                          <row-col>
                            <label>elements (only required for RPG Superstar, must include 1-3) <a data-toggle="modal" data-target="#elementsModal"><i class="fas fa-question-circle"></i></a></label>
                            <multiselect :init="target.elements" :options="appdata.element" v-model="target.elements">
                            </multiselect>
                          </row-col>
                        </row>
                        <row class="mt-1">
                            <row-col>
                                <label>languages</label>
                                <input class="form-control" placeholder="common, etc, etc" v-model="target.languages">
                            </row-col>
                        </row>
                        <row class="mt-1">
                            <row-col>
                                <label>description (markdown enabled)</label>
                                <textarea class="form-control" rows="12"  placeholder="You can reference the values set on the sheet with square brackets. For example [ac] will become the monster's armor class in the output. Anything can be referenced by its name, [acrobatics] [dex] [dc] [perception] and more.
								
Additionally you can use % to reference the name of the monster.
								
Basic syntax for bold, italic, and bullet points is supported. For example **bold** _italic_ and * bullet point." v-model="target.description"></textarea>
                            </row-col>
                        </row>
                        <row class="mt-1">
                            <row-col>
                                <label>art direction (only used for RPG Superstar entries, not required)</label>
                                <textarea class="form-control" rows="5" v-model="target.artdirection"></textarea>
                            </row-col>
                        </row>
                    </card>
                    <card header="Ability Modifiers">
                        <row v-for="ab,i of appdata.label.ability" :class="{'mt-1': i > 0}" :key="ab">
                            <row-col span="3">
                                <label v-if="i == 0">name</label>
                                <div class="btn text-uppercase text-label">{{ ab }}</div>
                            </row-col>
                            <row-col span="3">
                                <label v-if="i == 0">benchmark</label>
                                <dropselect init="moderate" v-model="target[ab].benchmark" :options="appdata.column.ability">
                                </dropselect>
                            </row-col>
                            <row-col span="2" style="max-width: 40px">
                                <label v-if="i == 0">hint</label>
                                <button class="btn-light btn w-100 text-right" data-show="popover" data-content="Apply Hint" @click="useHint('ability', ab)">{{ getHint('ability', ab) }}</button>
                            </row-col>
                            <row-col span="2" style="max-width: 40px">
                                <label v-if="i == 0">value</label>
                                <input class="form-control text-right" v-model="target[ab].value">
                            </row-col>
                            <row-col>
                                <label v-if="i == 0">note</label>
                                <input class="form-control" v-model="target[ab].note">
                            </row-col>
                        </row>
                    </card>
                    <card header="Defense">
                        <row v-for="ab,i of appdata.label.defense" :class="{'mt-1': i > 0}" :key="ab">
                            <row-col span="3">
                                <label v-if="i == 0">name</label>
                                <div class="btn text-uppercase text-label">{{ ab }}</div>
                            </row-col>
                            <row-col span="3">
                                <label v-if="i == 0">benchmark</label>
                                <dropselect init="moderate" v-model="target[appdata.alias.defense[i]].benchmark" :options="appdata.column[appdata.benchmark.defense[i]]">
                                </dropselect>
                            </row-col>
                            <row-col span="2" style="max-width: 40px">
                                <label v-if="i == 0">hint</label>
                                <button class="btn-light btn w-100 text-right" data-show="popover" data-content="Apply Hint" @click="useHint(appdata.benchmark.defense[i], appdata.alias.defense[i], ab != 'armor class')">{{ getHint(appdata.benchmark.defense[i], appdata.alias.defense[i], ab != 'armor class') }}</button>
                            </row-col>
                            <row-col span="2" style="max-width: 40px">
                                <label v-if="i == 0">value</label>
                                <input class="form-control text-right" v-model="target[appdata.alias.defense[i]].value">
                            </row-col>
                            <row-col>
                                <label v-if="i == 0">note</label>
                                <input class="form-control" v-model="target[appdata.alias.defense[i]].note">
                            </row-col>
                        </row>
                        <row class="mt-1">
                            <row-col span="3">
                                <div class="btn text-uppercase text-label">saves</div>
                            </row-col>
                            <row-col>
                                <input class="form-control" v-model="target.savenote">
                            </row-col>
                        </row>
                        <row class="mt-1">
                            <row-col span="3">
                                <label>name</label>
                                <div class="btn text-uppercase text-label">hit points</div>
                            </row-col>
                            <row-col>
                                <label>benchmark</label>
                                <dropselect init="moderate" v-model="target.hp.benchmark" :options="appdata.column.hp">
                                </dropselect>
                            </row-col>
                            <row-col style="max-width: 30px">
                                <label>min</label>
                                <div class="dropright">
                                    <input class="form-control text-right pl-0" :value="getRight(getHint('hp'))" readonly data-toggle="dropdown">
                                    <div class="dropdown-menu p-0 shadow-sm ml-n1">
                                        <button class="btn btn-sm px-05 btn-light" @click="target.hp.value = getRight(getHint('hp'))"><i class="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                            </row-col>
                            <row-col style="max-width: 30px">
                                <label>max</label>
                                <div class="dropright">
                                    <input class="form-control text-right pl-0" :value="getLeft(getHint('hp'))" readonly data-toggle="dropdown">
                                    <div class="dropdown-menu p-0 shadow-sm ml-n1">
                                        <button class="btn btn-sm px-05 btn-light" @click="target.hp.value = getLeft(getHint('hp'))"><i class="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                            </row-col>
                            <row-col span="2" style="max-width: 40px">
                                <label>value</label>
                                <input class="form-control text-right" v-model="target.hp.value">
                            </row-col>
                            <row-col>
                                <label>note</label>
                                <input class="form-control" v-model="target.hp.note">
                            </row-col>
                        </row>
                        <row v-for="ab,i of appdata.label.resist" class="mt-1" :key="ab">
                            <row-col span="3">
                                <label v-if="i == 0">name</label>
                                <div class="btn text-uppercase text-label">{{ ab }}</div>
                            </row-col>
                            <row-col style="max-width: 30px">
                                <label v-if="i == 0">min</label>
                                <input class="form-control text-right" :value="getHintObj(ab).minimum" readonly>
                            </row-col>
                            <row-col style="max-width: 30px">
                                <label v-if="i == 0">max</label>
                                <input class="form-control text-right" :value="getHintObj(ab).maximum" readonly>
                            </row-col>
                            <row-col>
                                <label v-if="i == 0">value</label>
                                <input class="form-control" v-model="target[ab].value">
                            </row-col>
                        </row>
                        <row class="mt-1">
                            <row-col span="3">
                                <div class="btn text-uppercase text-label">immunity</div>
                            </row-col>
                            <row-col>
                                <input class="form-control" v-model="target.immunity.value">
                            </row-col>
                        </row>
                    </card>
                    <card header="Perception">
                        <row>
                            <row-col span="3">
                                <label>benchmark</label>
                                <dropselect init="moderate" v-model="target.perception.benchmark" :options="appdata.column.perception">
                                </dropselect>
                            </row-col>
                            <row-col span="2" style="max-width: 40px">
                                <label>hint</label>
                                <button class="btn-light btn w-100 text-right" data-show="popover" data-content="Apply Hint" @click="useHint('perception')">{{ getHint('perception') }}</button>
                            </row-col>
                            <row-col span="2" style="max-width: 40px">
                                <label>value</label>
                                <input class="form-control text-right" v-model="target.perception.value">
                            </row-col>
                            <row-col>
                                <label>senses</label>
                                <input class="form-control" v-model="target.perception.note">
                            </row-col>
                        </row>
                    </card>
                    <card header="Skills">
                        <row v-for="s,i of appdata.label.skill" :class="{'mt-1': i > 0}" :key="s">
                            <row-col span="3">
                                <label v-if="i == 0">name</label>
                                <div class="btn text-uppercase btn-sm text-label" v-if="!('name' in target[s])">{{ s }}</div>
                                <input class="form-control form-control-sm text-uppercase" v-model="target[s].name" v-if="('name' in target[s])">
                            </row-col>
                            <row-col span="3">
                                <label v-if="i == 0">benchmark</label>
                                <dropselect init="moderate" v-model="target[s].benchmark" button="btn-light btn-sm" :options="appdata.column.skill">
                                </dropselect>
                            </row-col>
                            <row-col span="2" style="max-width: 40px">
                                <label v-if="i == 0">hint</label>
                                <button class="btn-light btn w-100 text-right" data-show="popover" data-content="Apply Hint" @click="useHint('skill', s)">{{ getHint('skill', s) }}</button>
                            </row-col>
                            <row-col span="2" style="max-width: 40px">
                                <label v-if="i == 0">value</label>
                                <input class="form-control text-right form-control-sm" v-model="target[s].value">
                            </row-col>
                            <row-col>
                                <label v-if="i == 0">note</label>
                                <input class="form-control form-control-sm" v-model="target[s].note">
                            </row-col>
                        </row>
                    </card>
                    <card header="Items">
                        <row>
                            <row-col style="max-width: 40px">
                                <label>Level</label>
                                <input class="form-control text-right" readonly :value="getHintObj('item').item">
                            </row-col>
                            <row-col>
                                <label>Items</label>
                                <input class="form-control" :placeholder="getHintObj('item').note" v-model="target.items">
                            </row-col>
                        </row>
                    </card>
                    <card header="Offense">
                        <row>
                            <row-col>
                                <label>speed</label>
                                <input class="form-control" v-model="target.speed">
                            </row-col>
                        </row>
                        <row class="mt-1">
                            <row-col span="3">
                                <label>type</label>
                                <div class="btn text-uppercase text-label">attack bonus</div>
                            </row-col>
                            <row-col>
                                <label>low</label>
                                <input class="form-control text-right text-low" readonly :value="'+' + getHintObj('attack').low">
                            </row-col>
                            <row-col>
                                <label>moderate</label>
                                <input class="form-control text-right text-moderate" readonly :value="'+' + getHintObj('attack').moderate">
                            </row-col>
                            <row-col>
                                <label>high</label>
                                <input class="form-control text-right text-high" readonly :value="'+' + getHintObj('attack').high">
                            </row-col>
                            <row-col>
                                <label>extreme</label>
                                <input class="form-control text-right text-extreme" readonly :value="'+' + getHintObj('attack').extreme">
                            </row-col>
                        </row>
                        <row class="mt-1">
                            <row-col span="3">
                                <div class="btn text-uppercase text-label">strike damage</div>
                            </row-col>
                            <row-col>
                                <input class="form-control text-right pl-0 text-low overflow-hidden" readonly :value="getHintObj('damage').low">
                            </row-col>
                            <row-col>
                                <input class="form-control text-right pl-0 text-moderate overflow-hidden" readonly :value="getHintObj('damage').moderate">
                            </row-col>
                            <row-col>
                                <input class="form-control text-right pl-0 text-high overflow-hidden" readonly :value="getHintObj('damage').high">
                            </row-col>
                            <row-col>
                                <input class="form-control text-right pl-0 text-extreme overflow-hidden" readonly :value="getHintObj('damage').extreme">
                            </row-col>
                        </row>
                        <draggable v-model="target.strikes" group="strikes" handle=".grab">
                            <row class="mt-1" v-for="st,i of target.strikes" :key="st.id">
                                <row-col style="max-width: 50px">
                                    <label v-if="i == 0">type</label>
                                    <div class="dropdown">
                                        <input class="form-control" v-model="st.type" data-toggle="dropdown">
                                        <div class="dropdown-menu dropdown-menu-left p-1 shadow-sm" style="width: 200%;margin-top:-4rem">
                                            <div class="d-flex justify-content-between">
                                                <button class="btn btn-sm btn-light" @click="st.type = 'Melee'">Melee</button>
                                                <button class="btn btn-sm btn-light" @click="st.type = 'Ranged'">Ranged</button>
                                            </div>
                                        </div>
                                    </div>
                                </row-col>
                                <row-col span="2">
                                    <label v-if="i == 0">name</label>
                                    <input class="form-control" v-model="st.name">
                                </row-col>
                                <row-col style="max-width: 40px">
                                    <label v-if="i == 0">atk</label>
                                    <div class="dropdown">
                                        <input data-toggle="dropdown" class="form-control text-right" v-model="st.attack">
                                        <div class="dropdown-menu dropdown-menu-right p-1 shadow-sm text-right" style="width: 300%;margin-top:-4rem">
                                            <div class="d-flex justify-content-between">
                                                <button v-for="h of appdata.column.attack" class="btn btn-sm btn-light px-05" :class="['text-' + h]" @click="st.attack = ensureSign(getHintObj('attack')[h])">{{ ensureSign(getHintObj('attack')[h]) }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </row-col>
                                <row-col>
                                    <label v-if="i == 0">traits</label>
                                    <input class="form-control" v-model="st.traits">
                                </row-col>
                                <row-col>
                                    <label v-if="i == 0">damage</label>
                                    <div class="dropdown">
                                        <input data-toggle="dropdown" class="form-control text-truncate" v-model="st.damage">
                                        <div class="dropdown-menu dropdown-menu-right p-1 allow-focus shadow-sm" style="width: 350px;margin-top:-5.75rem">
                                            <input class="form-control form-control-sm" v-model="st.damage">
                                            <div class="d-flex justify-content-between mt-1">
                                                <button v-for="h of appdata.column.attack" class="btn btn-sm btn-light px-05" :class="['text-' + h]" @click="st.damage = replaceDice(st.damage, trimParens(getHintObj('damage')[h]))">{{ trimParens(getHintObj('damage')[h]) }}</button>
                                                <button v-for="t of ['piercing', 'slashing', 'bludgeoning']" class="btn btn-sm btn-light px-05" @click="st.damage = replaceDamageType(st.damage, t)">{{ t }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </row-col>
                                <row-col style="max-width: 15px">
                                    <label v-if="i == 0" class="invisible">.</label>
                                    <div class="dropleft">
                                        <button class="btn btn-light px-0 w-100 text-muted border-left-0 border-right-0 small" data-toggle="dropdown"><i class=" fas fa-trash"></i></button>
                                        <div class="dropdown-menu p-1">
                                            <button class="btn btn-light text-danger w-100 text-uppercase text-right text-truncate" @click="removeStrike(st.id)" ><i class="fas fa-trash"></i> <small>delete</small></button>
                                        </div>
                                    </div>
                                </row-col>
                                <row-col style="max-width: 15px">
                                    <label v-if="i == 0" class="invisible">.</label>
                                    <div class="grab btn btn-light px-0 w-100 text-muted border-left-0 border-right-0"><i class=" fas fa-arrows-alt-v"></i></div>
                                </row-col>

                            </row>
                        </draggable>
                        <row class="mt-1"><row-col><button class="btn btn-light w-100 text-gray" @click="newStrike"><i class="fas fa-plus"></i></button></row-col></row>
                    </card>
                    <card header="Spells">
                        <row v-for="ab,i of appdata.label.spell" :class="{'mt-1': i > 0}" :key="ab">
                            <row-col span="3">
                                <label v-if="i == 0">name</label>
                                <div class="btn text-uppercase text-label">{{ ab }}</div>
                            </row-col>
                            <row-col span="3">
                                <label v-if="i == 0">benchmark</label>
                                <dropselect init="moderate" v-model="target[appdata.alias.spell[i]].benchmark" :options="appdata.column.spell">
                                </dropselect>
                            </row-col>
                            <row-col span="2" style="max-width: 40px">
                                <label v-if="i == 0">hint</label>
                                <button class="btn-light btn w-100 text-right" data-show="popover" data-content="Apply Hint" @click="useHint(appdata.benchmark.spell[i], appdata.alias.spell[i], ab == 'spell attack')">{{ getHint(appdata.benchmark.spell[i], appdata.alias.spell[i], ab == 'spell attack') }}</button>
                            </row-col>
                            <row-col span="2" style="max-width: 40px">
                                <label v-if="i == 0">value</label>
                                <input class="form-control text-right" v-model="target[appdata.alias.spell[i]].value">
                            </row-col>
                            <row-col>
                                <label v-if="i == 0">note</label>
                                <input class="form-control" v-model="target[appdata.alias.spell[i]].note">
                            </row-col>
                        </row>
                        <row class="mt-1">
                            <row-col span="3">
                                <label>type</label>
                                <div class="btn text-uppercase text-label">area damage</div>
                            </row-col>
                            <row-col>
                                <label>unlimited</label>
                                <input class="form-control text-right" readonly :value="getHintObj('area').unlimited">
                            </row-col>
                            <row-col>
                                <label>limited</label>
                                <input class="form-control text-right" readonly :value="getHintObj('area').limited">
                            </row-col>
                            <row-col>
                                <label>moderate dc</label>
                                <input class="form-control text-right" readonly :value="getHintObj('dc').moderate">
                            </row-col>
                        </row>
                        <row class="mt-1">
                            <row-col span="3">
                                <label>Spell Type</label>
                                <input class="form-control" v-model="target.spelltype">
                            </row-col>
                            <row-col span="1" style="max-width: 40px">
                                <label>Level</label>
                                <input class="form-control text-center" readonly :value="cantrip">
                            </row-col>
                            <row-col span="3">
                                <label>Ritual Type</label>
                                <input class="form-control" v-model="target.ritualtype">
                            </row-col>
                            <row-col>
                                <label>Rituals</label>
                                <input class="form-control" v-model="target.rituals">
                            </row-col>
                        </row>
                        <row v-for="ab,i of appdata.label.level" class="mt-1" :key="ab">
                            <row-col span="2">
                                <label v-if="i == 0">level</label>
                                <div class="btn text-uppercase text-label">{{ ab }}</div>
                            </row-col>
                            <row-col>
                                <label v-if="i == 0">value</label>
                                <input class="form-control" v-model="target.spells[i]">
                            </row-col>
                        </row>
                        <row class="mt-1">
                            <row-col span="2">
                                <div class="btn text-uppercase text-label">constant</div>
                            </row-col>
                            <row-col>
                                <input class="form-control" v-model="target.constant">
                            </row-col>
                        </row>
                    </card>
                    <card header="Additional Spells">
                        <draggable v-model="target.morespells" group="morespells" handle=".grab">
                            <row v-for="sp,i of target.morespells" :class="{'mt-1': i > 0}" :key="sp.id">
                                <row-col class="p-1 m-1 border rounded">
                                    <row>
                                        <row-col span="3">
                                            <label>name</label>
                                            <div class="btn text-uppercase text-label">spell attack</div>
                                        </row-col>
                                        <row-col span="3">
                                            <label>benchmark</label>
                                            <dropselect init="moderate" v-model="sp.spellattack.benchmark" :options="appdata.column.spell">
                                            </dropselect>
                                        </row-col>
                                        <row-col span="2" style="max-width: 40px">
                                            <label>hint</label>
                                            <button class="btn-light btn w-100 text-right" data-show="popover" data-content="Apply Hint" @click="sp.spellattack.value = getHint('spell', sp.spellattack.benchmark)">{{ getHint('spell', sp.spellattack.benchmark) }}</button>
                                        </row-col>
                                        <row-col span="2" style="max-width: 40px">
                                            <label>value</label>
                                            <input class="form-control text-right" v-model="sp.spellattack.value">
                                        </row-col>
                                        <row-col>
                                            <label>note</label>
                                            <input class="form-control" v-model="sp.spellattack.note">
                                        </row-col>
                                    </row>
                                     <row class="mt-1">
                                        <row-col span="3">
                                            <div class="btn text-uppercase text-label">spell dc</div>
                                        </row-col>
                                        <row-col span="3">
                                            <dropselect init="moderate" v-model="sp.spelldc.benchmark" :options="appdata.column.spell">
                                            </dropselect>
                                        </row-col>
                                        <row-col span="2" style="max-width: 40px">
                                            <button class="btn-light btn w-100 text-right" data-show="popover" data-content="Apply Hint" @click="sp.spelldc.value = getHint('dc', sp.spelldc.benchmark)">{{ getHint('dc', sp.spelldc.benchmark) }}</button>
                                        </row-col>
                                        <row-col span="2" style="max-width: 40px">
                                            <input class="form-control text-right" v-model="sp.spelldc.value">
                                        </row-col>
                                        <row-col>
                                            <input class="form-control" v-model="sp.spelldc.note">
                                        </row-col>
                                    </row>
                                    <row class="mt-1">
                                        <row-col span="3">
                                            <label>Spell Type</label>
                                            <input class="form-control" v-model="sp.name">
                                        </row-col>
                                        <row-col span="1" style="max-width: 40px">
                                            <label>Level</label>
                                            <input class="form-control text-center" readonly :value="cantrip">
                                        </row-col>
                                        <row-col>
                                            <label>Note</label>
                                            <input class="form-control" v-model="sp.note">
                                        </row-col>
                                        <row-col style="max-width: 15px">
                                            <label class="invisible">.</label>
                                            <div class="dropleft">
                                                <button class="btn btn-light px-0 w-100 text-muted border-left-0 border-right-0 small" data-toggle="dropdown"><i class=" fas fa-trash"></i></button>
                                                <div class="dropdown-menu p-1">
                                                    <button class="btn btn-light text-danger w-100 text-uppercase text-right text-truncate" @click="removeSpells(sp.id)" ><i class="fas fa-trash"></i> <small>delete</small></button>
                                                </div>
                                            </div>
                                        </row-col>
                                         <row-col style="max-width: 15px">
                                            <label class="invisible">.</label>
                                            <div class="grab btn btn-light px-0 w-100 text-muted border-left-0 border-right-0"><i class="fas fa-arrows-alt-v"></i></div>
                                        </row-col>
                                    </row>
                                    <row v-for="ab,j of appdata.label.level" class="mt-1" :key="ab">
                                        <row-col span="2">
                                            <label v-if="j == 0">level</label>
                                            <div class="btn text-uppercase text-label">{{ ab }}</div>
                                        </row-col>
                                        <row-col>
                                            <label v-if="j == 0">value</label>
                                            <input class="form-control" v-model="sp.spells[j]">
                                        </row-col>
                                    </row>
                                    <row class="mt-1">
                                        <row-col span="2">
                                            <div class="btn text-uppercase text-label">constant</div>
                                        </row-col>
                                        <row-col>
                                            <input class="form-control" v-model="sp.constant">
                                        </row-col>
                                    </row>
                                </row-col>
                            </row>
                        </draggable>
                        <row class="mt-1">
                            <row-col>
                                <button class="btn btn-light w-100 text-gray" @click="newSpells"><i class="fas fa-plus"></i></button>
                            </row-col>
                        </row>
                    </card>
                    <card header="Special Abilities">
                        <draggable v-model="target.specials" group="specials" handle=".grab">
                            <row v-for="sp,i of target.specials" :class="{'mt-1': i > 0}" :key="sp.id">
                                <row-col span="3">
                                    <label>Name</label>
                                    <input class="form-control" v-model="sp.name">
                                </row-col>
                                <row-col style="max-width: 70px">
                                    <label>Category</label>
                                    <dropselect init="offense" :options="appdata.special" v-model="sp.type"></dropselect>
                                </row-col>
                                <row-col style="max-width: 80px">
                                    <label>Actions</label>
                                    <dropselect init="one" :options="appdata.action" v-model="sp.actions"></dropselect>
                                </row-col>
                                <row-col>
                                    <label>Traits</label>
                                    <input class="form-control" v-model="sp.traits">
                                </row-col>
                                <row-col style="max-width: 15px">
                                    <label class="invisible">.</label>
                                    <div class="dropleft">
                                        <button class="btn btn-light px-0 w-100 text-muted border-left-0 border-right-0 small" data-toggle="dropdown"><i class=" fas fa-trash"></i></button>
                                        <div class="dropdown-menu p-1">
                                            <button class="btn btn-light text-danger w-100 text-uppercase text-right text-truncate" @click="removeSpecial(sp.id)" ><i class="fas fa-trash"></i> <small>delete</small></button>
                                        </div>
                                    </div>
                                </row-col>
                                <row-col style="max-width: 15px">
                                    <label class="invisible">.</label>
                                    <div class="grab btn btn-light px-0 w-100 text-muted border-left-0 border-right-0"><i class="fas fa-arrows-alt-v"></i></div>
                                </row-col>
                                <div class="w-100 mt-1"></div>
                                <row-col>
                                    <textarea class="form-control form-control-sm small" placeholder="You can add actions in your descriptions with the following text options: (A), (AA), and (AAA) for one-, two-, and three-action symbols, and (F) and (R) for free-actions and reactions" rows="4" v-model="sp.description"></textarea>
                                </row-col>
                            </row>
                        </draggable>
                        <row class="mt-1">
                            <row-col>
                                <button class="btn btn-light w-100 text-gray" @click="newSpecial"><i class="fas fa-plus"></i></button>
                            </row-col>
                        </row>
                    </card>
                </div>
                <card no-ui class="mt-4 mb-4 mb-md-0 small">
                    <div class="d-flex">
                        <div class="flex-even p-2">
                            <p class="line-height-120 text-muted">In the descriptions for special abilities, and the main monster description, you can reference the values set on the sheet with square brackets. For example [ac] will become the monster's armor class in the output. Anything can be referenced by its name, [acrobatics] [dex] [dc] [perception] and more.</p>
                            <p class="line-height-120 text-muted">Additionally you can use % to reference the name of the monster.</p>
                            <p class="line-height-120 text-muted">Basic syntax for bold, italic, and bullet points is supported. For example **bold** _italic_ and * bullet point.</p>
                        </div>
                        <div class="flex-even p-2">
                            <p class="line-height-120 text-muted">Choosing a roadmap will overwrite most of the core values of the monster with new ones generated by the roadmap. The skill paragon roadmaps will clear skills except for the relevant ones.</p>
							<p class="line-height-120 text-muted">You can add actions in your descriptions with the following text options: (A), (AA), and (AAA) for one-, two-, and three-action symbols, and (F) and (R) for free-actions and reactions.</p>
                            <p class="line-height-120 text-muted">Portions of this tool and code are used with permission from pf2.tools.</p>
                        </div>
                    </div>
                </card>
            </row-col>
            <row-col class="mx-auto" :class="[{'overflow-auto w-600': mode == 'edit'}, {'mb-3': mode == 'view'}]">
                <div class="bg-paper mb-1 rounded" :class="[{'p-2': mode == 'edit'}, {'p-3 text-left': mode == 'view'}]">
                    <div id="monster-output">
                        <div style="color: #000">
                        <div class="d-flex justify-content-between text-uppercase h3">
                            <div>{{ target.name || '&nbsp;' }}</div>
                            <div>{{target.creature || 'creature'}} {{ target.showlevel || target.level }}</div>
                        </div>
                        <div style="background: #777777; height: 2px; margin: 0.0 0 0.2rem"></div>
                        <row>
                            <div v-if="splitCommas(target.traits).includes('unique')" class="btn btn-pf btn-pf-unique text-uppercase">unique</div>
                            <div v-if="splitCommas(target.traits).includes('rare')" class="btn btn-pf btn-pf-rare text-uppercase">rare</div>
                            <div v-if="splitCommas(target.traits).includes('uncommon')" class="btn btn-pf btn-pf-uncommon text-uppercase">uncommon</div>
                            <div class="btn btn-pf btn-pf-alignment text-uppercase" v-if="target.alignment != '-'">{{ target.alignment }}</div>
                            <div class="btn btn-pf btn-pf-size text-uppercase" v-if="target.size != '-'">{{ target.size }}</div>
                            <div class="btn btn-pf text-uppercase" v-if="target.type != '-'">{{ target.type }}</div>
                            <template v-for="trait of splitCommas(target.traits).filter(t => t != 'rare' && t != 'uncommon' && t != 'unique' && t != '-' && t)" >
                                <div class="btn btn-pf text-uppercase" :key="trait">{{ trait }}</div>
                            </template>
                        </row>
                        <div class="font-sheet">
                            <div class="col p-0">
                                <div v-if="target.perception.value">
                                    <div class="line-height-120 hanging-indent"><span class="font-weight-bold">Perception</span>
                                    {{ ensureSign(target.perception.value) }}<span v-if="target.perception.note">;&nbsp;<span v-html="formatAll(target.perception.note)"></span></span>
                                    </div>
                                </div>
                                <div v-if="target.languages">
                                    <div class="line-height-120 hanging-indent"><span class="font-weight-bold">Languages</span>
                                    <span v-html="formatAll(target.languages)"></span></div>
                                </div>
                                <div v-if="appdata.label.skill.some(x => target[x].value)">
                                    <div class="line-height-120 hanging-indent"><span class="font-weight-bold">Skills</span>
                                    <span v-for="s,i in appdata.label.skill.filter(x => target[x].value).sort(sortSkills)"> {{titleCase(target[s].name || s)}}&nbsp;{{ensureSign(target[s].value)}}<span v-if="target[s].note"> (<span v-html="formatAll(target[s].note)"></span>)</span><template v-if="i != appdata.label.skill.filter(x => target[x].value).length - 1">,</template></span>
                                    </div>
                                </div>
                                <div>
                                    <div class="line-height-120 hanging-indent">
                                        <span class="font-weight-bold">Str</span>&nbsp;{{ensureSign(target.strength.value || 0)}}<template v-if="target.strength.note">&nbsp;<span v-html="formatAll(target.strength.note)"></span></template>,
                                        <span class="font-weight-bold">Dex</span>&nbsp;{{ensureSign(target.dexterity.value || 0)}}<template v-if="target.dexterity.note">&nbsp;<span v-html="formatAll(target.dexterity.note)"></span></template>,
                                        <span class="font-weight-bold">Con</span>&nbsp;{{ensureSign(target.constitution.value || 0)}}<template v-if="target.constitution.note">&nbsp;<span v-html="formatAll(target.constitution.note)"></span></template>,
                                        <span class="font-weight-bold">Int</span>&nbsp;{{ensureSign(target.intelligence.value || 0)}}<template v-if="target.intelligence.note">&nbsp;<span v-html="formatAll(target.intelligence.note)"></span></template>,
                                        <span class="font-weight-bold">Wis</span>&nbsp;{{ensureSign(target.wisdom.value || 0)}}<template v-if="target.wisdom.note">&nbsp;<span v-html="formatAll(target.wisdom.note)"></span></template>,
                                        <span class="font-weight-bold">Cha</span>&nbsp;{{ensureSign(target.charisma.value || 0)}}<template v-if="target.charisma.note">&nbsp;<span v-html="formatAll(target.charisma.note)"></span></template>
                                    </div>
                                </div>
                                <div v-for="sp,i in target.specials.filter(s => s.type == 'general')" :key="sp.id">
                                    <div class="line-height-120 hanging-indent"><span class="font-weight-bold">{{ sp.name }}</span>
                                        <action :act="sp.actions"></action>
                                        <span v-if="sp.traits"> ({{sp.traits}}) </span> <span class="text-pre" v-html="formatAll(sp.description)"></span>
                                    </div>
                                </div>
                                <div v-if="target.items">
                                    <div class="line-height-120 hanging-indent"><span class="font-weight-bold">Items</span>
                                    <span v-html="formatAll(target.items)"></span></div>
                                </div>
                            </div>
                            <div v-if="target.imgurl" class="flex-shrink-1 bg-img mr-2" :style="{minWidth: '150px', backgroundImage: 'url(' + target.imgurl + ')'}">
                            </div>
                        </div>
                        <div style="background: #cccccc; height: 2px; border-top: 1px solid #888"></div>
                        <div class="font-sheet">
                            <div class="line-height-120 hanging-indent">
                                <span class="font-weight-bold">AC</span>
                                {{target.ac.value || 0}}<template v-if="target.ac.note">,&nbsp;<span v-html="formatAll(target.ac.note)"></span></template>;
                                <span class="font-weight-bold">Fort</span>
                                {{ensureSign(target.fortitude.value || 0)}},<template v-if="target.fortitude.note" class="font-italic">&nbsp;<span v-html="formatAll(target.fortitude.note)"></span>,</template>
                                <span class="font-weight-bold">Ref</span>
                                {{ensureSign(target.reflex.value || 0)}},<template v-if="target.reflex.note" class="font-italic">&nbsp;<span v-html="formatAll(target.reflex.note)"></span>,</template>
                                <span class="font-weight-bold">Will</span>
                                {{ensureSign(target.will.value || 0)}}<template v-if="target.will.note" class="font-italic">,&nbsp;<span v-html="formatAll(target.will.note)"></span></template><template v-if="target.savenote">;&nbsp;<span v-html="formatAll(target.savenote)"></span></template>
                            </div>
                        </div>
                        <div class="font-sheet">
                            <div class="line-height-120 hanging-indent"><span class="font-weight-bold">HP</span>
                                {{target.hp.value || 1}}<template v-if="target.hp.note"><span v-if="target.hp.note.toLowerCase().includes('hardness')">;</span><span v-else>,</span>&nbsp;<span v-html="formatAll(target.hp.note)"></span></template><template v-if="target.immunity.value">; </template><span v-if="target.immunity.value"><span class="font-weight-bold">Immunities</span>&nbsp;<span v-html="formatAll(target.immunity.value)"></span></span><template v-if="target.resistance.value || target.weakness.value">; <template v-if="target.weakness.value"><span class="font-weight-bold">Weaknesses</span>&nbsp;<span v-html="formatAll(target.weakness.value)"></span></template></template><template v-if="target.weakness.value && target.resistance.value">; </template><template v-if="target.resistance.value"><span class="font-weight-bold">Resistances</span>&nbsp;<span v-html="formatAll(target.resistance.value)"></span></template>
                            </div>
                        </div>
                        <div v-for="sp,i in target.specials.filter(s => s.type == 'defense')" :key="sp.id" class="font-sheet">
                            <div class="line-height-120 hanging-indent"><span class="font-weight-bold">{{ sp.name }}</span>
                                <action :act="sp.actions"></action>
                                <span v-if="sp.traits"> ({{sp.traits}}) </span> <span class="text-pre" v-html="formatAll(sp.description)"></span>
                            </div>
                        </div>
                        <div style="background: #cccccc; height: 2px; border-top: 1px solid #888"></div>
                        <div class="font-sheet">
                            <div class="line-height-120 hanging-indent"><span class="font-weight-bold">Speed</span>
                              <span v-if="target.speed" v-html="formatAll(target.speed)"></span>
                              <span v-else>5 feet</span>
                            </div>
                            <div v-for="st,i in target.strikes" :key="st.id" class="font-sheet">
                                <div class="line-height-120 hanging-indent"><span class="font-weight-bold">{{ titleCase(st.type) || 'Melee'}}</span>
                                    <action act="one"></action>
                                    <span v-html="formatAll(st.name.toLowerCase())"></span> {{ ensureSign(st.attack || 0) }}<span v-if="st.traits"> ({{st.traits}})</span>, <span class="font-weight-bold">Damage</span> {{ st.damage }}
                                </div>
                            </div>
                            <div v-if="target.spellattack.value || target.spelldc.value" class="font-sheet">
                                <div class="line-height-120 hanging-indent">
                                    <span class="font-weight-bold">{{titleCase(target.spelltype)}} Spells</span>
                                    <template v-if="target.spelldc.value">DC {{target.spelldc.value}}</template><template v-if="target.spellattack.value">, attack {{ensureSign(target.spellattack.value)}}</template>;
                                    <span v-for="sp,i in nonEmptySpells(target.spells)" :key="i"><span class="font-weight-bold">{{appdata.label.level[sp.level]}}<template v-if="sp.level === 10">&nbsp;({{cantrip}})</template></span>&nbsp;<span class="font-italic" v-html="formatAll(sp.spell)"></span><span v-if="(i != nonEmptySpells(target.spells).length - 1) || (target.constant)">; </span> </span>
                                    <template v-if="target.constant"><span class="font-weight-bold">Constant </span> <span class="font-italic" v-html="formatAll(target.constant)"></span></template>
                                </div>
                                <div class="line-height-120 hanging-indent" v-for="ms in target.morespells">
                                    <span class="font-weight-bold">{{titleCase(ms.name)}} Spells</span>
                                    DC {{ms.spelldc.value || 0}},
                                    <template v-if="ms.spellattack.value">attack {{ensureSign(ms.spellattack.value)}}<span v-if="ms.note">{{' '}}{{ms.note}}</span>;</template>
                                    <span v-for="sp,i in nonEmptySpells(ms.spells)" :key="i"><template v-if="sp.spell"><span class="font-weight-bold">{{appdata.label.level[sp.level]}}<template v-if="sp.level === 10">&nbsp;({{cantrip}})</template></span>&nbsp;<span class="font-italic" v-html="formatAll(sp.spell)"></span><span v-if="(i != nonEmptySpells(ms.spells).length - 1) || (ms.constant)">; </span></template></span>
                                    <template v-if="ms.constant"><span class="font-weight-bold">Constant </span> <span class="font-italic" v-html="formatAll(ms.constant)"></span></template>
                                </div>
                            </div>
                            <div v-if="target.ritualtype" class="font-sheet">
                                <div class="line-height-120 hanging-indent"><span class="font-weight-bold">{{titleCase(target.ritualtype)}} Rituals</span>
                                    DC {{target.spelldc.value || 0}};
                                    <span class="font-italic" v-html="formatAll(target.rituals, false)"></span>
                                </div>
                            </div>
                            <div v-for="sp,i in target.specials.filter(s => s.type == 'offense')" :key="sp.id" class="font-sheet">
                                <div class="line-height-120 hanging-indent"><span class="font-weight-bold">{{ sp.name }}</span>
                                    <action :act="sp.actions"></action>
                                    <span v-if="sp.traits"> ({{sp.traits}}) </span> <span class="text-pre" v-html="formatAll(sp.description)"></span>
                                </div>
                            </div>
                            <div v-if="target.description" style="background: #cccccc; height: 2px; border-top: 1px solid #888"></div>
                            <div v-if="target.description">
                                <div class="mt-1 serif" v-html="md(target.description)"></div>
                            </div>
                            <div class="mt-1" v-if="target.imgurl">
                                <img :src="target.imgurl" class="img-thumbnail">
                            </div>
                        </div>
                        <div class="font-sheet">
                          <span class="word-count">Word Count: {{ target.wordCount }}</span><br/>
                          <br/>
                        </div>
                    </div>
                </div></div>
                <div v-if="target.elements && target.elements.length > 0" class="font-sheet elements">
                  <span class="font-weight-bold">Elements</span>
                  <span class="text-capitalize">{{ [...target.elements].sort().join(", ") }}</span>
                </div>
                <div class="font-sheet art-direction">
                  <span class="font-weight-bold">Art Direction</span>
                  {{ target.artdirection }}
                </div>
            </row-col>
        <!--</row>-->
        </div>
    </div>
    </div></div></div>

    <modal id="modal" :title="modalId" mw="640" >
        <component v-if="modalId" :is="modalId"/>
    </modal>

    <div class="modal fade" id="elementsModal" tabindex="-1" role="dialog" aria-labelledby="elementsModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body p-5">
            <button type="button" class="close position-absolute" data-dismiss="modal" aria-label="Close" style="top: 20px; right: 30px;">
              <span aria-hidden="true">&times;</span>
            </button>
            <p><strong>Fire</strong> is the element of flames, heat, and burning. Creatures associated with fire usually have the fire trait or special abilities with the fire trait.</p>
            <p><strong>Water</strong> is the element of water, rain, and bodies of water. Creatures associated with water usually have the water trait (and often the amphibious or aquatic trait too), and they might have special abilities with the water trait.</p>
            <p><strong>Earth</strong> is the element of earth, rock, soil, and the like. Creatures associated with earth usually have the earth trait or special abilities with the earth trait.</p>
            <p><strong>Air</strong> is the element of air, wind, and the sky. Creatures associated with air usually have the air trait or special abilities with the air trait, and they can often fly.</p>
            <p><strong>Wood</strong> is the element of wood, plant life, and flora of all kinds. Creatures associated with wood are usually plants, have special abilities associated with plants, or both.</p>
            <p><strong>Ice</strong> is the element of cold, ice, snow, and other frozen substances (but not liquid water). Creatures associated with ice usually have the cold trait or special abilities with the cold trait.</p>
            <p><strong>Lightning</strong> is the element of electricity and lightning. Creatures associated with lightning usually have the electricity trait or special abilities with the electricity trait.</p>
            <p><strong>Force</strong> is the element of mystical telekinetic force. Creatures associated with force usually have some kind of telekinetic abilities or other special abilities with the force trait.</p>
            <p><strong>Life</strong> is the element of raw life force, birth, and vital essence. Creatures associated with life usually have the positive trait or special abilities with the positive trait.</p>
            <p><strong>Death</strong> is the element of death, quietus, and the natural order of the reaping of life. Creatures associated with death usually have abilities associated with the death trait. They are very rarely undead (as undead have found a way to escape the fate of the death element), but they might be psychopomps or other natural bringers of death.</p>
            <p><strong>Light</strong> is the element of light, illumination, and vision. Creatures associated with light usually have special abilities with the light trait.</p>
            <p><strong>Darkness</strong> is the element of darkness, the absence of light, and sometimes the shadows that play upon the liminal spaces where light and darkness meet. Creatures associated with darkness usually have special abilities with the darkness trait or shadow trait.</p>
            <p><strong>Mind</strong> is the element of thoughts, emotions, and mental effects. Creatures associated with mind usually have special abilities with the mental trait. They might potentially have the astral or dream trait.</p>
            <p><strong>Spirit</strong> is the element of disembodied spirits built out of spiritual building blocks. Creatures associated with spirit are usually incorporeal spirits themselves, though perhaps they might not be if they have notable special abilities involving spirits.</p>
            <p><strong>Body</strong> is the element focusing on raw body, muscle, and the physical building blocks that make up a creature’s form. Creatures associated with body usually have strong martial attacks with the brute roadmap, or perhaps the soldier roadmap.</p>
            <p><strong>Music</strong> is the element of music, harmony, and sound. Creatures associated with music usually have auditory or sonic abilities, which often (but not always) include a musical motif.</p>
            <p><strong>Metal</strong> is the element of ore, smithing, and metal. Creatures associated with metal usually have abilities associated with metal, which might include the ability to create or boost metal items, or the ability to make attacks that mimic certain types of precious metals.</p>
            <p><strong>Time</strong> is the element of the past, present, and future. Creatures associated with time usually have some abilities to manipulate time. Be cautioned if you write a creature for this element that time abilities are especially difficult and tempting to write something too complicated or powerful; a true Superstar can write a time ability that is elegant and easy to use without breaking the action economy.</p>
            <p><strong>Space</strong> is the element of distance, the concept of three-dimensional space, as well as the emptiness of space that contains nothing else. Creatures associated with space usually have abilities with the teleportation trait, abilities based on entering or living in outer space, or perhaps both.</p>
            <p class="mb-0"><strong>Poison</strong> is the element of poisons, toxins, venoms, and other such substances inimical to living things. Creatures associated with poison usually have special abilities with the poison trait.</p>
          </div>
        </div>
      </div>
    </div>


    </template>

    </div>
    `
  })
};

